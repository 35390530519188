import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  ReferenceInput,
  SimpleForm,
  TextInput
} from "react-admin";

export const LimitedCodeForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput
      label="Desconto"
      source="discount"
      reference="discount"
      isRequired
    >
      <AutocompleteInput optionText="full_name" />
    </ReferenceInput>
    <TextInput
      multiline
      label="Códigos (um por linha)"
      fullWidth
      source="code"
    />
    <BooleanInput source="used" label="Utilizado?" disabled />
    <ReferenceInput
      label="Reservado para"
      source="reserved_for"
      reference="user"
      disabled
    >
      <AutocompleteInput disabled optionText="full_name" />
    </ReferenceInput>
  </SimpleForm>
);
