import React from "react";
import { GraphBox } from "../../molecules/GraphBox";
import { GRAPH_COLORS, MONTH } from "../../../constants";
import { Bar, Line } from "react-chartjs-2";
import { useCheckMobile } from "../../../utils";

export const ActivationUsageGraph = ({
  usageMonthlyCount,
  userMonthlyCount,
}) => {
  const isMobile = useCheckMobile();
  const totalValues = Object.values(usageMonthlyCount).length;
  const limit = isMobile ? 10 : totalValues;

  return (
    <GraphBox title="Usuários x Utilizações">
      <Bar
        width="100%"
        height={isMobile ? "100px" : "30px"}
        options={{
          elements: {
            line: {
              tension: 0,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        data={{
          labels: MONTH,
          datasets: [
            {
              label: "Utilização",
              data: Object.values(usageMonthlyCount),
              borderColor: GRAPH_COLORS[0],
              backgroundColor: GRAPH_COLORS[0],
              borderWidth: 1,
            },
            {
              label: "Novos Usuários",
              data: Object.values(userMonthlyCount),
              borderColor: GRAPH_COLORS[1],
              backgroundColor: GRAPH_COLORS[1],
              borderWidth: 1,
            },
          ],
        }}
      />
    </GraphBox>
  );
};
