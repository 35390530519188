import {
  Create
} from "react-admin";
import { CompanyTabbedForm } from "./CompanyTabbedForm";

export const CompanyCreate = (props) => (
  <Create title="Criação de Empresa" {...props}>
    <CompanyTabbedForm {...props} />
  </Create>
);
