import { useDataProvider, useInput } from "ra-core";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

export function DiscountTable(props) {
  const [discounts, setDiscounts] = useState([]);
  const {
    input: { name, onChange },
    meta: { touched, error },
  } = useInput(props);
  const dataProvider = useDataProvider();
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "full_name",
      headerName: "Nome",
      flex: 1,
    },
  ];

  useEffect(() => {
    dataProvider
      .getList("discount", {
        filter: { mode: "online", active: true },
        pagination: { perPage: 999999, page: 1 },
        sort: { field: "id", order: "DESC" },
      })
      .then(({ data }) => {
        setDiscounts(data);
      });
  }, [setDiscounts]);

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <DataGrid
        name={name}
        helperText={touched && error}
        onSelectionModelChange={(data) => {
          onChange(data);
        }}
        columns={columns}
        rows={discounts}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
