import { Spacer } from "@chakra-ui/react";
import React from "react";
import {
    SimpleForm
} from "react-admin";

export const VindiLogForm = (props) => (
    <SimpleForm {...props}>
        <h1 style={{ fontWeight: "bold" }}>JSON</h1>
        <p style={{ width: "100%" }}>
            {JSON.stringify(props.record.json)}
        </p>
        <Spacer height="40px" />
        <h1 style={{ fontWeight: "bold" }}>Erro</h1>
        <p style={{ width: "100%" }}>
            {JSON.stringify(props.record.error)}
        </p>

    </SimpleForm>
);
