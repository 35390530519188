import {
  HStack,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  Textarea,
  Box,
} from "@chakra-ui/react";
import {  useState } from "react";
import { SubmitStepButton } from "./SubmitStepButton";
import { useNotificationCentralContext } from "../notification-central.context";
import { Labeled } from "../../../../components/molecules/Labeled";

export function ChooseReceiverStep({ HeaderComponent, onSubmit, colors }) {
  const { setFormData, formData } = useNotificationCentralContext();

  const [sendMethod, setSendMethod] = useState(
    formData?.sendToAll || formData?.notificationType == "push"
      ? "partiu"
      : "manual-email-list"
  );
  const [emailList, setEmailList] = useState(
    formData?.emailsList.join("\n") || ""
  );

  const handleSubmit = () => {
    // @ts-ignore
    setFormData((oldData) => {
      return {
        ...oldData,
        emailsList: emailList.split("\n").filter((s) => s !== ""),
        sendToAll: sendMethod === "partiu",
      };
    });
    onSubmit();
  };

  return (
    <>
      <HeaderComponent title={"Destinatários"} />
      <HStack width={"100%"} alignItems={"flex-start"}>
        <Box paddingY={2} width={{ base: "100%", lg: "600px" }}>
          <Text fontWeight={"bold"} marginBottom={4}>
            Selecione abaixo os destinatátios
          </Text>
          <RadioGroup onChange={setSendMethod} value={sendMethod}>
            <Stack>
              <Radio
                value="manual-email-list"
                isDisabled={formData.notificationType !== "email"}
              >
                Inserir lista de email
              </Radio>
            </Stack>
          </RadioGroup>
          <SubmitStepButton
            colors={colors}
            onSubmit={handleSubmit}
            isDisabled={
              !(
                sendMethod === "partiu" ||
                (sendMethod === "manual-email-list" && emailList.length > 0)
              )
            }
          />
        </Box>
        {sendMethod === "manual-email-list" && (
          <Box flex={1}>
            <Labeled
              label={"Lista de Emails"}
              helpText={
                "Insira os emails aqui. Cada email deve estar em uma linha"
              }
              footerText={
                emailList.split("\n").filter((s) => s !== "").length +
                " emails."
              }
            >
              <Textarea
                minHeight={"150px"}
                onChange={(e) => setEmailList(e.target.value)}
                minWidth={"450px"}
                value={emailList}
              />
            </Labeled>
          </Box>
        )}
      </HStack>
    </>
  );
}