import React from "react";
import { GraphBox } from "../../molecules/GraphBox";
import { HorizontalBar } from "react-chartjs-2";
import { useCheckMobile } from "../../../utils";
import { GRAPH_COLORS } from "../../../constants";

export const HighestUsageGraph = ({ title, highestUsageData, flex = 1 }) => {
  const isMobile = useCheckMobile();

  return (
    <GraphBox flex={flex} title={title}>
      <HorizontalBar
        height={isMobile ? "200px" : "150px"}
        options={{
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        data={{
          labels: highestUsageData.map((h) => h.discount__company__name),
          datasets: [
            {
              label: "Utilizações",
              data: highestUsageData.map((h) => h.discount__count),
              backgroundColor: GRAPH_COLORS,
            },
          ],
        }}
      />
    </GraphBox>
  );
};
