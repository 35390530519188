import { Stack, Text } from '@chakra-ui/react';
import { SectionTitle } from '../atoms/Title.js';

export function Labeled({
  label,
  helpText,
  footerText,
  children,
  labelSize = '12px',
  ...props
}) {
  return (
    <Stack width={'100%'} align={'flex-start'} {...props}>
      <SectionTitle fontSize={labelSize}>{label}</SectionTitle>
      {helpText && (
        <Text marginTop={'-5px'} fontSize={10} color={'#a9a9a9'}>
          {helpText}
        </Text>
      )}
      {children}
      {footerText && (
        <Text marginTop={'-5px'} fontSize={10} color={'#a9a9a9'}>
          {footerText}
        </Text>
      )}
    </Stack>
  );
}
