import { Flex } from "@chakra-ui/react";
import React from "react";
import { Statistics } from "../components/organisms/Statistics";

export const Dashboard = () => {
  return (
    <Flex id="#box-main" direction="column" width="100%">
      <Statistics />
    </Flex>
  );
};
