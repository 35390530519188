import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput
} from "react-admin";
import { AutoLoginButton } from "../../../components/atoms/AutoLoginButton";
import { QualityTagField } from "../../../components/atoms/QualityTagField";
import { ToggleInputField } from "../../../components/atoms/ToggleInputField";

export const StoreFilter = (props) => (
  <Filter {...props}>
    <TextInput
      style={{ minWidth: "400px" }}
      source="search"
      label="Busca por nome, cidade, estado, bairro"
      alwaysOn
    />
    <ReferenceInput
      reference="company"
      source="company"
      label="Empresa"
      alwaysOn
    >
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <BooleanInput source="active" label="Ativo" />
  </Filter>
);

const StorePagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 15]} {...props} />
);

export const StoreList = (props) => (
  <List
    filters={<StoreFilter />}
    perPage={5}
    pagination={<StorePagination />}
    title="Lojas"
    sort={{ field: "id", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <ReferenceField label="Empresa" source="company" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="state" label="Estado" />
      <TextField source="city" label="Cidade" />
      <TextField source="neighborhood" label="Bairro" />
      <TextField source="street" label="Endereço" />
      <QualityTagField
        source="registration_quality"
        label="Qualidade Cadastro"
      />
      <NumberField source="usage" label="Utilizações" />
      <ToggleInputField source="active" label="Ativo" />
      <DateField showTime={true} source="created_at" label="Criado em" />
      <AutoLoginButton
        label="Visão Lojista"
        source="pdv_et"
        url="https://pdv.partiu.com.br/"
      />
      <EditButton />
    </Datagrid>
  </List>
);
