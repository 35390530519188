import { Box, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";

export const Header = ({ step, setStep, isMobile }) => {
  return (
    <Box>
      <Box
        paddingRight={isMobile ? 4 : 0}
        paddingLeft={isMobile ? 4 : 8}
        marginTop={6}
        gap={12}
        xs={12}
        md={10}
        lg={10}
        xl={10}
        display={"flex"}
        justifyContent={isMobile ? "center" : ""}
      >
        <Box
          xs={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          gap={"48px"}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 8,
              cursor: 'pointer'
            }}
            onClick={() => setStep(1)}
          >
            <Box
              style={{
                width: "56px",
                height: "56px",
                background: step === 1 ? "#db3657" : "#5CB88D",
                borderRadius: "20px",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FAFAFA",
                }}
              >
                {step === 1 ? 1 : <Check />}
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "40px",
                color: step === 1 ? "#000000" : "#5CB88D",
              }}
            >
              Cadastro
            </Typography>
          </Box>
          <Box
            style={{
              width: "56px",
              height: "2px",
              border: "solid",
              borderBottomWidth: "2px",
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              borderBottomColor: step > 1 ? "#5CB88D" : "#00000099",
            }}
          ></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 8,
              cursor: 'pointer'
            }}
            onClick={() => setStep(2)}
          >
            <Box
              style={{
                width: "56px",
                height: "56px",
                background: step > 1 ? step === 2 ? "#db3657" : "#5CB88D" : "#db365799",
                borderRadius: "20px",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FAFAFA",
                }}
              >
                {step < 3 ? 2 : <Check />}
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "40px",
                color: step > 1 ? step === 2 ? "#000000" : "#5CB88D" : "#00000099",
              }}
            >
              Módulos
            </Typography>
          </Box>
          <Box
            style={{
              width: "56px",
              height: "2px",
              border: "solid",
              borderBottomWidth: "2px",
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              borderBottomColor: step > 1 ? "#5CB88D" : "#00000099",
            }}
          ></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 8,
              cursor: 'pointer'
            }}
            onClick={() => setStep(3)}
          >
            <Box
              style={{
                width: "56px",
                height: "56px",
                background: step > 2 ? step === 3 ? "#db3657" : "#5CB88D" : "#db365799",
                borderRadius: "20px",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FAFAFA",
                }}
              >
                {step < 4 ? 3 : <Check />}
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "40px",
                color: step > 2 ? step === 3 ? "#000000" : "#5CB88D" : "#00000099",
              }}
            >
              WhiteLabel
            </Typography>
          </Box>
          <Box
            style={{
              width: "56px",
              height: "2px",
              border: "solid",
              borderBottomWidth: "2px",
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              borderBottomColor: step > 3 ? "#5CB88D" : "#00000099",
            }}
          ></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 8,
              cursor: 'pointer'
            }}
            onClick={() => setStep(4)}
          >
            <Box
              style={{
                width: "56px",
                height: "56px",
                background: step > 3 ? step === 4 ? "#db3657" : "#5CB88D" : "#db365799",
                borderRadius: "20px",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FAFAFA",
                }}
              >
                {step < 5 ? 4 : <Check />}
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "40px",
                color: step > 3 ? step === 4 ? "#000000" : "#5CB88D" : "#00000099",
              }}
            >
              Integrações
            </Typography>
          </Box>
          <Box
            style={{
              width: "56px",
              height: "2px",
              border: "solid",
              borderBottomWidth: "2px",
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              borderBottomColor: step > 5 ? "#5CB88D" : "#00000099",
            }}
          ></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 8,
              cursor: 'pointer'
            }}
            onClick={() => setStep(5)}
          >
            <Box
              style={{
                width: "56px",
                height: "56px",
                background: step > 4 ? step === 5 ? "#db3657" : "#5CB88D" : "#db365799",
                borderRadius: "20px",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#FAFAFA",
                }}
              >
                {step < 6 ? 5 : <Check />}
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "40px",
                color: step > 4 ? step === 5 ? "#000000" : "#5CB88D" : "#00000099",
              }}
            >
              Apps
            </Typography>
          </Box>
          <Box
            style={{
              width: "56px",
              height: "2px",
              border: "solid",
              borderBottomWidth: "2px",
              borderTopWidth: "0px",
              borderLeftWidth: "0px",
              borderRightWidth: "0px",
              borderBottomColor: step > 5 ? "#5CB88D" : "#00000099",
            }}
          ></Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: 8,
              cursor: 'pointer'
            }}
            onClick={() => setStep(6)}
          >
            <Box
              style={{
                width: "56px",
                height: "56px",
                background: step === 6 ? "#db3657" : "#db365799",
                borderRadius: "20px",
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "12px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  color: "#FAFAFA",
                }}
              >
                6
              </Typography>
            </Box>
            <Typography
              style={{
                fontFamily: "Raleway",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "40px",
                color: step === 6 ? "#000000" : "#00000099",
              }}
            >
              Legal
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}