import React from "react";
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const UserForm = (props) => (
  <SimpleForm {...props}>
    <TextInput
      required={true}
      source="full_name"
      fullWidth
      label="Nome Completo"
    />
    <TextInput required={true} source="email" fullWidth label="Email" />
    <TextInput required={true} source="cpf" fullWidth label="CPF" />
    <TextInput
      required={true}
      source="password"
      fullWidth
      label="Senha (deixe em branco para manter senha)"
    />
    <ImageField source="image" label="Imagem Atual" />

    <ImageInput source="image" label="Imagem de Perfil">
      <ImageField source="image" title="Imagem de Perfil" />
    </ImageInput>
    <TextInput source="vindi_customer_id" label="ID Vindi" />
    <BooleanInput
      source="is_admin"
      label="Tem acesso ao banco de dados (CUIDADO COM ESSA OPÇÃO)"
    />
    <ReferenceInput
      label="Convênio Ativo"
      source="active_agreement"
      reference="agreement"
      allowEmpty={true}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Usuário PDV?" source="user_pdv" reference="store">
      <AutocompleteInput optionText="full_name" />
    </ReferenceInput>
    <ReferenceArrayInput
      label="Dono de convênio?"
      source="agreement_staff"
      reference="agreement"
      suggestionLimit={1}
    >
      <AutocompleteArrayInput optionText="name" suggestionLimit={10} />
    </ReferenceArrayInput>
    <BooleanInput
      source={"agreement_superuser"}
      label={"Administrador do Convênio"}
    />
  </SimpleForm>
);
