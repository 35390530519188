import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Filter,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput,
} from "react-admin";

export const CommunicationFilter = (props) => (
  <Filter {...props}>
    <TextInput
      source="search"
      label="Busca por título, desconto, cidade e estado"
      alwaysOn
    />
    <ReferenceInput
      reference="agreement"
      source="agreement"
      label="Convênio"
      alwaysOn
    >
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <DateTimeInput source="date" label="Data de Envio" />
  </Filter>
);

const CommunicationPagination = (props) => (
  <Pagination rowsPerPageOptions={[20, 30, 40]} {...props} />
);

export const CommunicationList = (props) => {
  return (
    <List
      sort={{ field: "id", order: "DESC" }}
      perPage={20}
      filters={<CommunicationFilter />}
      pagination={<CommunicationPagination />}
      title="Descontos"
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ReferenceField
          source="communication_batch"
          reference="communication_batch"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="title" label="Título" />
        <TextField source="text" label="Texto" />
        <DateField source="date" showTime label="Data de Envio" />
        <ReferenceField
          reference="discount"
          source="discount"
          label="Sobre desconto"
        >
          <TextField source="full_name" />
        </ReferenceField>
        <ReferenceField
          reference="agreement"
          source="agreement"
          label="Convênio"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
