import { useState } from "react"
import AWS from 'aws-sdk';

export function InputImage({ onUpload, imageName }) {

    const [image, setImage] = useState(null)

    const uploadFile = async (file) => {
        const S3_BUCKET = "partiu-appimages";
        const REGION = "sa-east-1";

        AWS.config.update({
            accessKeyId: "AKIARGTPCOJMCEQZIEWA",
            secretAccessKey: "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",
        });

        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: `${imageName}.png`,
            Body: file,
        };

        const upload = s3
            .putObject(params)
            .on("httpUploadProgress", (evt) => {
                console.log(
                    "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                );
            })
            .promise();

        await upload.then((err, data) => {
            console.log(err, data);
            onUpload(`https://partiu-appimages.s3.sa-east-1.amazonaws.com/${imageName}.png`)
            setImage(`https://partiu-appimages.s3.sa-east-1.amazonaws.com/${imageName}.png`)
            alert("File uploaded successfully.");
        });

    }

    return (
        <>
            <input accept="image/png" type="file" onChange={(e) => uploadFile(e.target.files[0])} />
            <img width={200} height={200} src={image} />
        </>
    )
}