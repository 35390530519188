import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRecordContext } from "react-admin";

export function CheckNullField(props) {
  const { source } = props;
  const record = useRecordContext(props);
  let value = record[source];

  if (!value) return <></>;

  return <FontAwesomeIcon icon={faCheck} />;
}
