import { Edit, Create } from "react-admin";

export function fromForm(FormElement) {
  return {
    edit: (props) => {
      return (
        <Edit {...props} title={`Editando`}>
          <FormElement {...props} />
        </Edit>
      );
    },

    create: (props) => {
      return (
        <Create {...props} title={`Criando`}>
          <FormElement {...props} />
        </Create>
      );
    },
  };
}
