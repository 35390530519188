export const allTemplates = {
  welcome: {
    'Mensagem de boas vindas': (agreementName) => ({
      subject: 'Você agora faz parte do Clube ' + agreementName,
      title: 'Você agora faz parte do Clube ' + agreementName,
      text: `Olá, tudo bem? <br/><br/> <b>Temos uma ótima notícia!</b><br/><br/>\Gostaríamos de apresentar a você o nosso Programa de Relacionamento com o cliente.<br/><br/>Com o <b>Clube ${agreementName}</b> você e sua família terão acesso a um mundo de vantagens, como descontos em várias grandes marcas ecommerce, cinemas, lojas físicas, livros digitais, entre outros.<br/><br/>\E o melhor, cliente ${agreementName} é <b>gratuito</b>, veja como é simples:<br/><br/>1. Faça download do app no link abaixo.<br/>2. Clique em "Com CPF Vinculado".<br/>3. Insira suas informações e aproveite!<br/><br/>Atenciosamente,<br/><br/><b>Equipe Clube ${agreementName}</b>`,
    }),
  },
  others: {},
  promotion: {
    'Desconto': (agreementName) => ({
      subject: 'Acesse as melhores promoções do clube ' + agreementName,
      title: 'Acesse as melhores promoções do clube ' + agreementName,
      text: `Aproveite as melhores ofertas do nosso clube`,
    })
  },
  specialDates: {
    'Dia Das Mães': (agreementName) => ({
      subject: 'Feliz Dia das Mães - Clube ' + agreementName,
      title: 'Descontos especiais no clube ' + agreementName,
      text: `Sua mãe merece o melhor, aproveite as melhores ofertas do nosso clube.<br/> Clique no botão abaixo e confira as promoções especiais para o Dia das Mães.`,
    }),
    'Dia Das Pais': (agreementName) => ({
      subject: 'Feliz Dia das Pais - Clube ' + agreementName,
      title: 'Descontos especiais no clube ' + agreementName,
      text: `Seu pai merece o melhor, aproveite as melhores ofertas do nosso clube.<br/> Clique no botão abaixo e confira as promoções especiais para o Dia dos Pais.`,
    }),
    'Natal': (agreementName) => ({
      subject: 'Feliz Natal - Clube ' + agreementName,
      title: 'Descontos especiais no clube ' + agreementName,
      text: `O espírito natalino está no ar e queremos aproveitar este momento para agradecer por fazer parte do nosso clube de vantagens.<br/> <br/>Desejamos a você e sua família um Natal repleto de alegria, amor e muitas ofertas exclusivas. Confira as vantagens especiais que preparamos para esta época do ano e aproveite para presentear quem você ama!`,
    }),
    'Ano Novo': (agreementName) => ({
      subject: 'Feliz Ano Novo - Clube ' + agreementName,
      title: 'Descontos especiais no clube ' + agreementName,
      text: `O Ano Novo está chegando e, com ele, novas oportunidades e muitos benefícios exclusivos no nosso clube de vantagens.<br/><br/>Estamos ansiosos para continuar trazendo ofertas incríveis e vantagens especiais para você em 2025. Acesse agora e descubra as novidades que preparamos!`,
    }),
    'Dia dos namorados': (agreementName) => ({
      subject: 'Feliz Dia dos Namorados - Clube ' + agreementName,
      title: 'Descontos especiais no clube ' + agreementName,
      text: `O Dia dos Namorados está se aproximando e queremos ajudar você a tornar essa data ainda mais especial.<br/><br/>Descubra nossas ofertas exclusivas para surpreender a pessoa amada com presentes incríveis e experiências inesquecíveis. Não perca tempo e aproveite agora!`,
    }),
  },
};
