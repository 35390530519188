import React from "react";
import { useRecordContext } from "react-admin";

export function QualityTagField(props) {
  const { source } = props;
  const record = useRecordContext(props);
  const value = record[source];

  let color;
  let label;

  if (value === 0) {
    color = "#cc0000";
    label = "Incompleto";
  } else if (value > 0 && value <= 5) {
    color = "#cc0000";
    label = "Básico";
  } else if (value > 5 && value <= 7) {
    color = "#ff9966";
    label = "Intermediário";
  } else if (value > 7 && value < 10) {
    color = "#ffcc00";
    label = "Bom";
  } else {
    color = "#99cc33";
    label = "Completo";
  }

  return (
    <div
      style={{
        padding: 5,
        backgroundColor: color,
        color: "white",
        textShadow: "0px 0px 5px rgba(0,0,0,0.5)",
        borderRadius: 20,
        textAlign: "center",
        fontSize: 10,
        fontWeight: "bold",
      }}
    >
      {label.toUpperCase()}
    </div>
  );
}
