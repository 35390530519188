import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import UploadFile from "./UploadFile";
import { useState } from "react";
import axios from "axios";

export const Step5 = ({ isMobile, state, setState }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);

  return (
    <Box>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          required={true}
          source="name"
          variant="outlined"
          fullWidth
          label="Nome do Aplicativo"
          value={state.name}
          onChange={(e) => {
            setState({ ...state, name: e.target.value });
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Nome do aplicativo que irá aparecer nas lojas
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          required={true}
          disabled
          value={state.slug}
          source="slug"
          variant="outlined"
          fullWidth
          label="Slug"
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Nome da página, a mesma utilizada no WhiteLabel
        </Typography>
      </Grid>
      <UploadFile title="Icone do Aplicativo" selectedFile={selectedFile} setSelectedFile={setSelectedFile} link={state.icon} setLink={(link) => {
        setState({ ...state, icon: link });
      }} />
      <UploadFile title="SplashScreen" selectedFile={selectedFile1} setSelectedFile={setSelectedFile1} link={state.splashscreen} setLink={(link) => {
        setState({ ...state, splashscreen: link });
      }} />
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          required={true}
          value={state.projectId}
          source="projectId"
          variant="outlined"
          fullWidth
          label="013aad3b-6ab9-4a4e-b32b-4a63584950f3"
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Id de projeto
        </Typography>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={async () => {
          await axios.post('https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/actions/workflows/create-new-app.yml/dispatches',
            {
              ref: "main",
              inputs: {
                name: state.name,
                slug: state.slug,
                bundleIdentifier: `br.com.partiu.${state.slug}`,
                appBaseUrl: `${state.slug}.partiu.com.br`,
                icon: state.icon,
                splash: state.splashscreen,
                adaptiveIcon: state.icon,
                projectId: state.projectId
              }

            },
            {
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/vnd.github+json",
                "Authorization": "Bearer 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",
                "X-GitHub-Api-Version": "2022-11-28"
              }
            })

          alert("Aplicativo subido com sucesso!")
        }}>Upload App</Button>
    </Box>
  );
}