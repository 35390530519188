import React from "react";
import {
  AutocompleteInput,
  Datagrid,
  EditButton,
  Filter,
  ImageField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from "react-admin";
import { ToggleInputField } from "../../components/atoms/ToggleInputField";

const BannerFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Nome" alwaysOn />
    <SelectInput
        alwaysOn
        label="Tipo"
        source="type"
        choices={[
            { id: "discount", name: "Desconto" },
            { id: "book", name: "Livro" },
        ]}
    />
    <ReferenceInput source="discount" reference="discount" alwaysOn label="Desconto">
      <AutocompleteInput source="discount" optionText={"id"} />
    </ReferenceInput>
  </Filter>
);

export const BannersList = (props) => {
  return (
    <List
      filters={<BannerFilter />}
      title="Banners"
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />
        <ImageField source="image" label="image" />
        <ToggleInputField source="active" label="Ativo" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
