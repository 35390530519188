import { Checkbox, Input, Spacer, Stack, VStack } from '@chakra-ui/react';
import { SubmitStepButton } from './SubmitStepButton';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';

import { useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNotificationCentralContext } from '../notification-central.context';
import { templateBuilder } from './template';
import { Labeled } from '../../../../components/molecules/Labeled';

export function EditMessageStep({ onSubmit, HeaderComponent, colors }) {
  const { formData } = useNotificationCentralContext();
  const emailEditorRef = useRef(null);

  const onReady = (unlayer) => {
    const initialTemplate = templateBuilder(colors.logo, colors, formData.emailTitle, formData.emailBody);

    unlayer.loadDesign(initialTemplate);
  };

  const { register, watch } = useForm({
    defaultValues: {
      ...formData,
      emailBody: formData?.emailBody,
      emailTitle: formData?.emailTitle,
    },
  });

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const emailTitle = watch('emailTitle');
  const emailBody = watch('emailBody');
  const emailSubject = watch('emailSubject');

  const { setFormData } = useNotificationCentralContext();

  const handleSubmitStep = () => {
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const { html } = data;
      setFormData((oldState) => ({
        ...oldState,
        html: html,
        emailBody,
        emailSubject,
        emailTitle,
      }));
      onSubmit();
    });
  };

  return (
    <>
      <HeaderComponent title={'Edite o template antes de enviar'} />
      <Stack direction={['column']} width="100%" alignItems={'center'} justifyContent={'center'} spacing={5}>
        <Stack direction={['column']} spacing={1} w={'100%'}>
          <form style={{ height: '100%', marginTop: 25 }}>
            <VStack height={'100%'} spacing={5}>
              <Labeled label={'Assunto'}>
                <Input {...register('emailSubject', { required: true })} />
              </Labeled>
              <Labeled label={'Título'}>
                <Input {...register('emailTitle', { required: true })} />
              </Labeled>
            </VStack>
          </form>
        </Stack>
        <Spacer />
        <EmailEditor
          options={{
            designMode: 'email',
            locale: 'pt-BR',
          }}
          ref={emailEditorRef}
          onReady={onReady}
        />
        <Checkbox isChecked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)}>
          Estou ciente de que o conteúdo do email é de minha responsabilidade
        </Checkbox>
        <div style={{ marginLeft: 'auto' }}>
          <SubmitStepButton colors={colors} onSubmit={handleSubmitStep} isDisabled={!acceptedTerms} />
        </div>
      </Stack>
    </>
  );
}
