import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  DateTimeInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TabbedForm,
  TextInput
} from "react-admin";

export const CompanyTabbedForm = (props) => (
  <TabbedForm {...props}>
    <FormTab label="Informações Básicas">
      <TextInput source="corporate_name" label="Razão Social" />
      <TextInput required={true} source="name" label="Nome Fantasia" />
      <NumberInput
        defaultValue={0}
        source="order"
        label="Rank (quanto maior o rank mais alto aparecerá essa empresa)"
      />
      <TextInput source="description" multiline fullWidth label="Descrição" />
      <ReferenceInput
        label="Disponível nos Planos"
        source="plans"
        reference="plan"
        isRequired
      >
        <SelectArrayInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Categoria"
        source="field_of_activity"
        reference="category"
        isRequired
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Exclusivo"
        source="agreement"
        reference="agreement"
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        defaultValue="approved"
        source="status"
        label="Status"
        choices={[
          { id: "approved", name: "Aprovado" },
          { id: "pending-approval", name: "Pendente de Aprovação" },
          {
            id: "pending-terms",
            name: "Pendente de Assinatura do Contrato Digital",
          },
          { id: "pending-training", name: "Pendente de Treinamento" },
          { id: "denied", name: "Negado" },
        ]}
      />
      <DateTimeInput
        source="approved_terms_date"
        disabled
        label="Data de Aprovação do Contrato Digital"
      />
      <ReferenceInput
        label="Criado por Convênio"
        source="creator_agreement"
        reference="agreement"
        disabled
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput label="Criado no(a)" disabled source="creator_origin" />
      <BooleanInput source="approved_data" disabled label="Aprovou os Dados" />
      <BooleanInput defaultValue={true} source="active" label="Ativo" />
    </FormTab>
    <FormTab label="Informações de Contato">
      <TextInput source="owner" label="Nome do Responsável" />
      <TextInput source="telephone" label="Telefone" />
      <TextInput source="cellphone" label="Celular" />
      <TextInput source="email" label="Email" />
      <TextInput source="site" label="Site" />
    </FormTab>
    <FormTab label="Imagens">
      <ImageField source="logo" label="Logo Atual" />
      <ImageInput source="logo" label="Logo Empresa">
        <ImageField source="logo" title="Logo" />
      </ImageInput>
      <ImageField source="cover_photo" label="Imagem Principal Atual" />
      <ImageInput source="cover_photo" label="Imagem Principal">
        <ImageField source="cover_photo" title="Imagem Principal" />
      </ImageInput>
    </FormTab>
    <FormTab label="Lojas"></FormTab>
  </TabbedForm>
);
