import React from "react";
import {
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput, SelectInput,
} from "react-admin";

export const AgreementPlanForm = (props) => (
    <SimpleForm {...props}>
        <TextInput required={true} source="name" label="Nome" />
        <SelectInput label={"Tipo de Plano"} source="type" choices={[
            {id:'company', name:"Para Empresas (SaaS)"},
            {id:'user', name: "Para Usuário (Assinatura)"}
        ]}/>
        <SelectInput label={"Nível do Plano"} defaultValue={0} source="level" choices={[
            {id: 0, name: "Não se aplica"},
            {id: 1, name: "Bronze"},
            {id: 2, name: "Prata"},
            {id: 3, name: "Ouro"},
            {id: 4, name: "Diamante"},
            {id: 5, name: "Light"}
        ]}/>
        <TextInput
            disabled={true}
            source="uuid"
            label="UUID"
            helperText="Gerado automaticamente após criação. Usar na Vindi."
        />
        <NumberInput
            source="access_amount"
            label="Quantidade de Acessos"
            helperText="Quantidade de acessos liberados após pagamento."
        />
        <NumberInput
            source="value"
            label="Preço do Plano"
        />
        <TextInput
            source="vindi_page"
            label="Link da Vindi"
            helperText="Link da página da Vindi."
        />
        <TextInput
            source="vindi_token"
            label="Token da Vindi"
            helperText="Token da página da Vindi. (Pode ser encontrado na mesma página do link)"
        />
        <ReferenceInput
            label="Plano vendido pelo convênio?"
            source="for_agreement"
            reference="agreement"
        >
        <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput
            source="slug"
            label="Slug do plano"
            helperText="Slug é o depara entre o recebido pela integração do cliente e esse plano"
        />
    </SimpleForm>
);
