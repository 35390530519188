import React from "react";
import {
    BooleanInput,
    SimpleForm,
    ImageField,
    ImageInput,
    TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput, AutocompleteInput, ReferenceInput, SelectInput,
} from "react-admin";

export const BookActivityForm = (props) => (
    <SimpleForm {...props}>
        <TextInput required={true} source="name" label="Nome" />
        <SelectInput source="type" label="Tipo de Mídia" choices={[{ id: "book", name: "Livro" }, { id: "magazine", name: "Revista" },{ id: "audiobook", name: "Audiobook" }]} />
        <ReferenceInput label="Setor" reference="book_sector" source="sector">
            <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceArrayInput
            reference="book_category"
            source="categories"
            label="Categorias"
        >
            <AutocompleteArrayInput isFullWidth />
        </ReferenceArrayInput>
        <ImageField source="image" label="Imagem Principal Antiga" />
        <ImageInput source="image" label="Imagem Principal">
            <ImageField source="image" />
        </ImageInput>
        <BooleanInput source="active" label="Ativo" defaultValue={true} />
    </SimpleForm>
);
