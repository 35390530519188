import { PartiuClient } from "@partiu-vantagens/partiu-sdk";
import axios from "axios";

export const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const partiuClient = new PartiuClient(
  process.env.NODE_ENV === "development"
);

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  validateStatus: (status) => {
    return status >= 200 && status < 400;
  },
});

export const axiosInstanceV2 = axios.create({
  baseURL: baseUrl.replace("v1", "") + "/api/v2",
});

export const isLogged = () => {
  return axiosInstance.defaults.headers.common["Authorization"] != null;
};

export const setAccessToken = (token) => {
  if (!token || token === "null")
    return (axiosInstance.defaults.headers.common["Authorization"] = null);
  axiosInstance.defaults.headers.common["Authorization"] = `Token ${token}`;
};

const token = localStorage.getItem("token");

if (token) setAccessToken(token);
