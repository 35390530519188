import { Heading } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HoverBox } from "../atoms/HoverBox";

export const BigStatisticCard = ({ icon, title, value }) => {
  const primaryColor = "rgb(229, 58, 53)";

  return (
    <HoverBox padding={5}>
      <VStack alignItems="flex-start" width="100%">
        <Heading marginBottom="40px" width="100%">
          {title}
        </Heading>
        <FontAwesomeIcon
          style={{ alignSelf: "center", color: primaryColor }}
          size="5x"
          icon={icon}
        />
        <Heading
          paddingTop="50px"
          fontSize="30px !important"
          alignSelf="center"
        >
          {value}
        </Heading>
      </VStack>
    </HoverBox>
  );
};
