import { Stack, Button } from '@chakra-ui/react';

export function SubmitStepButton({ onSubmit, isDisabled = false, colors }) {
  return (
    <Stack justifyContent="flex-start" alignItems="flex-start" marginTop="48px" width="100%">
      <Button
        onClick={onSubmit}
        backgroundColor={colors.primaryColor}
        color={colors.primaryFontColor}
        paddingX={12}
        isDisabled={isDisabled}
      >
        Avançar
      </Button>
    </Stack>
  );
}
