import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from "react-admin";
import { ModeField } from "../../components/atoms/ModeField";
import { MoneyField } from "../../components/atoms/MoneyField";

export const UsageFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      reference="company"
      source="discount__company"
      label="Empresa"
      alwaysOn
    >
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <TextInput source="user_pdv__user_pdv__state" label="Estado" alwaysOn />
    <TextInput source="user_pdv__user_pdv__city" label="Cidade" alwaysOn />
    <ReferenceInput reference="user" source="user" label="Usuário">
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceInput
      reference="discount"
      source="discount"
      label="Desconto"
      alwaysOn
    >
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <SelectInput
      source="discount__mode"
      choices={[
        { id: "online", name: "E-Commerce" },
        { id: "local", name: "Físico" },
      ]}
      label="Modo de Desconto"
    />
    <SelectInput
      source="discount__type"
      choices={[
        { id: "discount", name: "Desconto" },
        { id: "buy_and_gain", name: "Compre e Ganhe" },
        { id: "treat", name: "Mimo" },
      ]}
      label="Tipo de Desconto"
    />
  </Filter>
);

const UsagePagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />
);

export const UsageList = (props) => (
  <List
    pagination={<UsagePagination />}
    filters={<UsageFilter />}
    title="Utilizações"
    sort={{ field: "created_at", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="ID" />
      <ReferenceField label="Modo" source="discount" reference="discount">
        <ModeField source="mode" />
      </ReferenceField>
      <ReferenceField label="Empresa" source="company" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Convênio"
        source="agreement"
        reference="agreement"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Usuário" source="user" reference="user">
        <TextField source="full_name" />
      </ReferenceField>
      <ReferenceField label="Desconto" source="discount" reference="discount">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Loja" source="store" reference="store">
        <TextField source="address" />
      </ReferenceField>
      <MoneyField source="saved" label="Valor Economizado" />
      <DateField showTime={true} source="created_at" label="Criado em" />
    </Datagrid>
  </List>
);
