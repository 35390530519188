import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  FormTab,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  DateTimeInput,
  AutocompleteInput,
} from "react-admin";

import { axiosInstance } from "../../../axios";

import { useFormState } from "react-final-form";

const CityInput = (props) => {
  const { values } = useFormState();
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/api/v1/cities/?state=" + values.state || "")
      .then(({ data }) => {
        setCities(data.map((d) => ({ name: d })));
      });
  }, [values.state]);

  return (
    <AutocompleteInput
      source="city"
      choices={cities}
      optionText="name"
      optionValue="name"
      label="Cidade"
    />
  );
};

export const CommunicationTabbedForm = (props) => {
  const [states, setStates] = useState([]);

  useEffect(() => {
    axiosInstance.get("/api/v1/states/").then(({ data }) => {
      setStates(data);
    });
  }, []);

  return (
    <TabbedForm {...props}>
      <FormTab label="Informações Básicas">
        <TextInput isRequired source="title" label="Título" />
        <TextInput isRequired source="text" multiline label="Texto" />
        <DateTimeInput isRequired source="date" label="Data de Envio" />

        <ReferenceInput
          source="discount"
          reference="discount"
          label="Referente à algum desconto?"
        >
          <AutocompleteInput optionText="full_name" />
        </ReferenceInput>
        <BooleanInput
          defaultChecked
          source="send_push_notification"
          label="Enviar por Push Notification"
        />
        <BooleanInput
          defaultChecked
          source="send_internal_notification"
          label="Enviar por Notificação Interna"
        />
      </FormTab>
      <FormTab label="Filtragem">
        <ReferenceInput
          source="agreement"
          reference="agreement"
          label="Somente para convêniados"
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="state"
          choices={states}
          optionText="name"
          optionValue="state"
          label="Estado"
        />
        <CityInput />
      </FormTab>
    </TabbedForm>
  );
};
