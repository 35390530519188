import { Button } from "@material-ui/core";
import Web from "@material-ui/icons/Web";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  FunctionField,
  EditButton,
} from "react-admin";


export const AccessLandingButton = (props) => {
  const record = useRecordContext(props);
  let value = record["slug"];

  return (
    <a href={`https://${value}.partiu.com.br`} target="_blank" rel="noreferrer">
      <Button color="primary" startIcon={<Web />}>
        Acessar
      </Button>
    </a>
  );
};

const Status = {
  created: "Criado",
  in_processing: "Build Criada",
  published: "Publicado",
}

export const FluxList = ({ needsApproval, ...props }) => {
  return (
    <List
      title="Gerenciador de Apps"
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        <TextField source="agreement_name" label="Convênio" />
        <TextField source="name" label="Nome" />
        <TextField source="slug" label="Slug" />
        <TextField source="bundleIdentifier" label="Bundle Id" />
        <FunctionField label="Status" render={(record) => Status[record.status]  }  />
        <DateField showTime={true} source="created_at" label="Criado em" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
