import React from "react";
import {
  BooleanInput,
  SimpleForm,
  ImageField,
  ImageInput,
  TextInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

export const ActivityForm = (props) => (
  <SimpleForm {...props}>
    <TextInput required={true} source="name" label="Nome" />
    <ReferenceArrayInput
      reference="category"
      source="categories"
      label="Categorias"
    >
      <AutocompleteArrayInput isFullWidth />
    </ReferenceArrayInput>
    <ImageField source="image" label="Imagem Principal Antiga" />
    <ImageInput source="image" label="Imagem Principal">
      <ImageField source="image" />
    </ImageInput>
    <BooleanInput source="active" label="Ativo" defaultValue={true} />
  </SimpleForm>
);
