import { Switch } from "@material-ui/core";
import React from "react";
import { useDataProvider, useRecordContext } from "react-admin";

export function ToggleInputField(props) {
  const { source, resource } = props;
  const dataProvider = useDataProvider();
  const record = useRecordContext(props);
  const value = record[source];

  const handleChange = (event) => {
    dataProvider.update(resource, {
      id: record.id,
      data: { [source]: event.target.checked },
    });
  };

  return (
    <Switch checked={value} onChange={handleChange} name="activeChecked" />
  );
}
