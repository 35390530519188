import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

export default function Card({
  title = "",
  number = 0,
  bg = "secondary.card",
  color = "gray.800",
}) {
 
  return (
    <Box 
      width="100%" 
      rounded="lg" 
      p={5}
      bg={bg}
      color={color}
    >
      <Text fontWeight={"bold"}>{title}</Text>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="4em" lineHeight="4rem" fontWeight="700">
          {number}
        </Text>
      </Flex>
    </Box>
  );
}
