import * as React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
  TextInput
} from "react-admin";

export const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Busca por nome, email, cpf"
      style={{ minWidth: 300 }}
      source="search"
      alwaysOn
    />
    <ReferenceInput
      reference="agreement"
      source="active_agreement"
      label="Convênio"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <BooleanInput
      label="é Admin"
      source="is_admin"
      alwaysOn
      defaultChecked={false}
    />

    <BooleanInput
      label="é PDV"
      source="is_pdv"
      alwaysOn
      defaultChecked={false}
    />
    <BooleanInput
      label="Têm CPF"
      source="has_cpf"
      alwaysOn
      defaultChecked={false}
    />
  </Filter>
);

export const UserList = (props) => (
  <List
    filters={<UserFilter />}
    title="Usuários"
    sort={{ field: "id", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="full_name" label="Nome Completo" />
      <TextField source="email" label="Email" />
      <TextField source="cpf" label="CPF" />
      <ReferenceField
        label="Convênio"
        source="active_agreement"
        reference="agreement"
      >
        <TextField source="name" label="Convênio" />
      </ReferenceField>
      <TextField source="vindi_customer_id" label="ID Vindi" />
      <DateField showTime={true} source="created_at" label="Criado em" />
      <EditButton />
    </Datagrid>
  </List>
);
