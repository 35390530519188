import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput,
} from "react-admin";
import { MoneyField } from "../../../components/atoms/MoneyField";
import { ToggleInputField } from "../../../components/atoms/ToggleInputField";

const CategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Nome" alwaysOn />
    <BooleanInput source="active" label="Ativo" />
  </Filter>
);

export const CategoryList = (props) => {
  return (
    <List
      sort={{ field: "id", order: "DESC" }}
      filters={<CategoryFilter />}
      title="Categorias"
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />
        <NumberField source="companies" label="Empresas" />
        <NumberField source="usage" label="Utilização" />
        <MoneyField source="total_transacted" label="Total Transacionado" />
        <ToggleInputField source="active" label="Ativo" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
