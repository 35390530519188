import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { LoadingIndicator, SidebarToggleButton, UserMenu } from 'react-admin';

const MyAppBar = props => {
    return (
        <AppBar style={{ padding: "0px 0px", background: "linear-gradient(90deg, rgb(216, 43, 96), rgb(255, 168, 0))" }} {...props}>
            <Toolbar>
                <SidebarToggleButton />
                <img style={{ height: "30px", paddingLeft: "10px" }} src="/partiu_logo_black_white.png" />
                <Typography
                    style={{ paddingLeft: "20px", fontWeight: 800, fontSize: "14pt" }}
                    variant="h6"
                    color="inherit"
                    id="react-admin-title"
                />
                <span style={{ flex: 1 }} />
                <LoadingIndicator />
                <UserMenu {...props} />
            </Toolbar>
        </AppBar>)
};

export default MyAppBar;