import { Button } from "@material-ui/core";
import Web from "@material-ui/icons/Web";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  FunctionField,
} from "react-admin";


export const AccessLandingButton = (props) => {
  const record = useRecordContext(props);
  let value = record["slug"];

  return (
    <a href={`https://${value}.partiu.com.br`} target="_blank" rel="noreferrer">
      <Button color="primary" startIcon={<Web />}>
        Acessar
      </Button>
    </a>
  );
};

export const NotificationList = ({ needsApproval, ...props }) => {
  return (
    <List
      title="Central de notificações"
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        <TextField source="email_subject" label="Assunto do Email" />
        <FunctionField label="Recorrente" render={(record) => record.scheduleType === 'recurring' ? 'Sim' : 'Não' }  />
        <DateField showTime={true} source="created_at" label="Criado em" />
      </Datagrid>
    </List>
  );
};
