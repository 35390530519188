import React, { useRef } from "react";
import {
  Box,
  Button,
  Image,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
} from "@chakra-ui/react";
import ModalCreate from "./ModalCreate";
import { IconButton } from "@chakra-ui/react";
import { Delete, Edit } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import { axiosInstance } from "../../../axios";

const SuperAppMain = () => {
  const modalRef = useRef();
  const [list, setList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const aggrement = useLocation().pathname.split("/")[2];

  const handleDelete = async (benefit) => {
    try {
      await axiosInstance.delete(
        `/api/v1/agreement_modules_admin/${benefit.id}/`
      );
      getList();
    } catch (error) {
      console.log(error);
    }
  };

  const getList = async () => {
    try {
      setIsLoading(true);

      const { data } = await axiosInstance.get(
        "/api/v1/agreement_modules_admin/",
        {
          params: {
            agreement: aggrement,
            page_size: 100
          },
        }
      );
      setList(data.results);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getList();
  }, []);

  return (
    <Box width="100%" alignItems="center" justifyContent="center">
      <Button onClick={() => modalRef.current?.open()}>
        Adicionar novo modulo
      </Button>
      <Box marginTop="20px">
        {isLoading && <Text>Carregando...</Text>}
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Título</Th>
              <Th>Categoria</Th>
              <Th>Icone</Th>
              <Th>Url</Th>
            </Tr>
          </Thead>
          <Tbody>
            {list?.map((benefit, index) => (
              <Tr key={index}>
                <Td>
                  <Text fontWeight="bold">{benefit.title}</Text>
                </Td>
                <Td>
                  <Text>{benefit.section_title}</Text>
                </Td>
                <Td>
                  <Image
                    src={benefit.icon}
                    width={50}
                    height={50}
                    objectFit="contain"
                  />
                </Td>
                <Td>
                  <Text>{benefit.url}</Text>
                </Td>
                <Td>
                  <Flex gap={2}>
                    <IconButton
                      icon={<Edit />}
                      onClick={() => modalRef.current.open(benefit)}
                      aria-label="Editar"
                    />
                    <IconButton
                      icon={<Delete />}
                      onClick={() => handleDelete(benefit)}
                      aria-label="Deletar"
                    />
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <ModalCreate reload={getList} ref={modalRef} />
    </Box>
  );
};

export default SuperAppMain;
