import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React from "react";

export const Discount = ({
  image,
  mode,
  tag = <></>,
  title,
  subTitle,
  distance,
  forceMode = "cover",
  onClick = () => {},
  primaryColor,
  secondaryColor,
  secondaryFontColor,
}) => {
  return (
    <Flex
      className={`discount-${mode}`}
      borderRadius="15px"
      boxShadow="0 0 10px rgb(0 0 0 / 10%)"
      height={!subTitle ? "130px" : "160px"}
      direction="column"
      background="white"
      width="100%"
      cursor="pointer"
      position="relative"
      onClick={onClick}
      transition="0.2s"
      _hover={{
        boxShadow: "0 10px 10px rgb(0 0 0 / 20%)",
        transform: "translateY(-5px)",
      }}
    >
      <Image
        borderTopRightRadius="15px"
        borderTopLeftRadius="15px"
        height={!subTitle ? "73%" : "68%"}
        objectFit={forceMode}
        src={image}
        transition="0.5s"
        _hover={{}}
        borderBottom="1px solid rgba(0,0,0,.1)"
      />
      <Box position="absolute" top="5px" right="5px">
        <Box
          border="0px"
          as="button"
          borderRadius="100px"
          height="45px"
          p="5px 10px"
          borderColor="black.300"
          marginRight="10px"
          backgroundColor={secondaryColor}
        >
          <Text
            fontWeight="bold"
            fontSize="12px"
            color={secondaryFontColor}
            letterSpacing="1px"
          >
            10%
          </Text>
        </Box>
      </Box>
      <Text
        background="white"
        color="rgba(0,0,0,0.3)"
        border="1px solid rgba(0,0,0,.1)"
        borderRadius="10px"
        position="absolute"
        right="10px"
        top={!subTitle ? "85px" : "99px"}
        fontSize="11px"
        padding="0px 10px"
      >
        {mode}
      </Text>
      <Flex
        paddingTop="10px"
        paddingLeft="10px"
        align="flex-start"
        direction="column"
        textAlign="left"
      >
        <Heading textAlign="left" fontSize="14px" color={primaryColor}>
          {title.substring(0, 15) + (title.length > 15 ? "..." : "")}
        </Heading>
        {subTitle ? (
          <Text marginTop="0px" color="rgba(0,0,0,0.6)" fontSize="10px">
            {subTitle.substring(0, 20) + (subTitle.length > 20 ? "..." : "")}
          </Text>
        ) : (
          <></>
        )}
      </Flex>
      {distance && distance != "0" ? (
        <Text
          background="white"
          color="rgba(0,0,0,0.3)"
          border="1px solid rgba(0,0,0,.1)"
          borderRadius="10px"
          position="absolute"
          right="10px"
          top="99px"
          fontSize="10px"
          padding="0px 10px"
          fontWeight="bolder"
        >
          {distance}
        </Text>
      ) : (
        <></>
      )}
    </Flex>
  );
};
