import defaultTheme from "ra-ui-materialui/lib/defaultTheme";
import merge from "lodash/merge";
import { red } from "@material-ui/core/colors";

export const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: { main: "rgb(229, 58, 53)" },
    secondary: { main: "#db3657" },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    body1: {
      fontSize: "10pt"
    },
    body2: {
      fontSize: "10pt"
    },
    button: {
      fontSize: "10pt"
    }
  },
  sidebar: {
    width: 220,
  },
});
