import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import { axiosInstance } from "../../axios";
import { Header } from "./components/Header";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { Step4 } from "./components/Step4";
import { Step5 } from "./components/Step5";
import { Step6 } from "./components/Step6";

import { useRedirect } from 'react-admin';


export const Flux = ({ ...props }) => {
  const redirect = useRedirect();

  const [width,] = useState(window.innerWidth);
  const [step, setStep] = useState(1);

  const [body, setBody] = useState({
    primary_color: "#db3657",
    secondary_color: "#FAFAFA",
    primary_font_color: "#000",
    secondary_font_color: "#000",
    menu_color: "#000",
    menu_font_color: "#FFF",
    agreement_superuser: false,
    created_at: new Date(),
    updated_at: new Date()
  });

  const [landing, setLanding] = useState({});


  const handleChangeStep = async (step) => {
    if (step >= 6) {
      let response;
      try {
        setStep(6)
        const data = new FormData();
        const keys = Object.keys(body);

        keys.map(item => data.append(item, body[item]))

        response = await axiosInstance.post(
          `/api/v1/agreement_admin/`,
          data,
          {
            headers: {
              "Content-Type": 'multipart/form-data'
            }
          }
        );

        console.log(response.data)
        alert("Criado com sucesso!")
        redirect('agreement')
      } catch (e) {
        console.log(response?.data || "Erro ao chamar a API.")
        alert(response?.data || "Erro ao chamar a API.")
      }
    } else if (step <= 1) {
      setStep(1)
    } else {
      setStep(step)
    }
  };

  const isMobile = width <= 768;
  return (
    <Box>
      <Header step={step} setStep={handleChangeStep} isMobile={isMobile} />
      <Box marginLeft={isMobile ? 4 : 8} marginTop={4}>
        {step === 1 && <Step1 isMobile={isMobile} state={body} setState={setBody} />}
        {step === 2 && <Step2 isMobile={isMobile} state={body} setState={setBody} />}
        {step === 3 && <Step3 isMobile={isMobile} state={body} setState={setBody} landing={landing} setLanding={setLanding} />}
        {step === 4 && <Step4 isMobile={isMobile} state={body} setState={setBody} />}
        {step === 5 && <Step5 isMobile={isMobile} state={body} setState={setBody} />}
        {step === 6 && <Step6 isMobile={isMobile} state={body} setState={setBody} />}
      </Box>
      <Box
        style={{
          position: 'absolute',
          bottom: '10vh',
          right: '20vh',
          width: "200px",
          height: "56px",
          background: "#db3657",
          borderRadius: "20px",
          display: "flex",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "12px",
          cursor: 'pointer',
          userSelect: 'none'
        }}
        onClick={() => handleChangeStep(step + 1)}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "24px",
            lineHeight: "40px",
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            color: "#FAFAFA",
          }}
        >
          {step === 6 ? "Finalizar" : "Próximo"}
        </Typography>
      </Box>
    </Box>
  );
}
