import { Link, Button } from "@chakra-ui/react";
import React from "react";
import { useRecordContext } from "react-admin";

export function AutoLoginButton(props) {
  const { source } = props;
  const record = useRecordContext(props);
  let value = record[source];

  return (
    <Link
      textDecoration="none !important"
      href={value ? props.url + "login/" + value : "/"}
      target="_blank"
    >
      <Button size="sm" disabled={!value} style={{ fontSize: "10pt" }} colorScheme="green">
        Acessar
      </Button>
    </Link>
  );
}
