import React from "react";
import { AutocompleteInput, Create, ReferenceInput, SelectInput, SimpleForm } from "react-admin";
export const MagazineCreate = (props) => (
    <Create title="Gerar revista" {...props}>
        <SimpleForm {...props}>
            <ReferenceInput
                label="Convênio para gerar a revista"
                source="agreement"
                reference="agreement"
                allowEmpty={true}
            >
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectInput source="reference_month" label={"Mês"} defaultValue={0} choices={[
                { id: 0, name: "Janeiro" },
                { id: 1, name: "Fevereiro" },
                { id: 2, name: "Março" },
                { id: 3, name: "Abril" },
                { id: 4, name: "Maio" },
                { id: 5, name: "Junho" },
                { id: 6, name: "Julho" },
                { id: 7, name: "Agosto" },
                { id: 8, name: "Setembro" },
                { id: 9, name: "Outubro" },
                { id: 10, name: "Novembro" },
                { id: 11, name: "Dezembro" },
            ]} />
            <SelectInput source="reference_year" label={"Ano"} defaultValue="2024" choices={[
                { id: 2023, name: "2023" },
                { id: 2024, name: "2024" },
                { id: 2025, name: "2025" },
            ]} />
        </SimpleForm>
    </Create>
);
