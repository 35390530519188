import { Spacer } from "@chakra-ui/react";
import React from "react";
import {
    BooleanInput,
    DateInput,
    NumberInput,
    SimpleForm,
    TextInput
} from "react-admin";

export const SurfRechargeForm = (props) => (
    <SimpleForm {...props}>
        {/* { source: "id", field: NumberField, label: "ID" },
            { source: "document", field: TextField, label: 'Documento' },
            { source: "cellphone", field: TextField, label: 'Telefone' },
            { source: "created_at", field: DateField, label: "Criado em" },
            { source: "updated_at", field: DateField, label: "Atualizado em" },
            { source: "reference_date", field: DateField, label: "Recarga em" },
            { source: "audiobook_quantity", field: NumberField, label: "Quantidade de Audiobooks" },
            { source: "book_quantity", field: NumberField, label: "Quantidade de livros" },
            { source: "amount", field: NumberField, label: "Valor" },
            { source: "used", field: BooleanField, label: "Usado?" }, */}
        <TextInput source="document" label="Documento (CPF/CNPJ)" />
        <TextInput source="cellphone" label="Telefone" />
        <NumberInput source="audiobook_quantity" label="Total de audiobooks" />
        <NumberInput source="book_quantity" label="Total de livros" />
        <NumberInput source="amount" label="Valor da recarga"/>
        <DateInput source="reference_date" label="Data da recarga"/>
        <BooleanInput source="used" />
        <Spacer height="40px" />

    </SimpleForm>
);
