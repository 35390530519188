import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput
} from "react-admin";
import { CEPInput } from "../../../components/atoms/CEPInput";
import { MapInput } from "../../../components/molecules/MapInput";

export const StoreTabbedForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="Informações Básicas">
        <ReferenceInput
          label="Empresa"
          source="company"
          reference="company"
          isRequired
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="active" label="Ativo" defaultValue={true} />
        <TextInput
          source="country"
          isRequired
          label="País"
          defaultValue="Brasil"
        />
        <CEPInput />
        <SelectInput
          disabled
          choices={[
            { id: "AC", name: "Acre" },
            { id: "AL", name: "Alagoas" },
            { id: "AM", name: "Amazonas" },
            { id: "AP", name: "Amapá" },
            { id: "BA", name: "Bahia" },
            { id: "CE", name: "Ceará" },
            { id: "DF", name: "Distrito Federal" },
            { id: "ES", name: "Espírito Santo" },
            { id: "GO", name: "Goiás" },
            { id: "MA", name: "Maranhão" },
            { id: "MG", name: "Minas Gerais" },
            { id: "MS", name: "Mato Grosso do Sul" },
            { id: "MT", name: "Mato Grosso" },
            { id: "PA", name: "Pará" },
            { id: "PB", name: "Paraíba" },
            { id: "PE", name: "Pernambuco" },
            { id: "PI", name: "Piauí" },
            { id: "PR", name: "Paraná" },
            { id: "RJ", name: "Rio de Janeiro" },
            { id: "RN", name: "Rio Grande do Norte" },
            { id: "RO", name: "Rondônia" },
            { id: "RR", name: "Roraima" },
            { id: "RS", name: "Rio Grande do Sul" },
            { id: "SC", name: "Santa Catarina" },
            { id: "SE", name: "Sergipe" },
            { id: "SP", name: "São Paulo" },
            { id: "TO", name: "Tocantins" },
          ]}
          source="state"
          label="Estado"
        />
        <TextInput disabled source="city" label="Cidade" />
        <TextInput source="neighborhood" label="Bairro" />
        <TextInput source="street" label="Rua" />
        <NumberInput source="number" label="Número" />
      </FormTab>
      <FormTab label="Localização">
        <NumberInput source="latitude" label="Latitude" />
        <NumberInput source="longitude" label="Longitude" />
        <MapInput />
      </FormTab>
    </TabbedForm>
  );
};
