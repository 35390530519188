
import { axiosInstance } from '../../../../axios.js';

export async function createAgreementNotifications(data) {
  return axiosInstance.post(`/api/v1/notifications_emails_admin/`, {
    send_to_all: data.sendToAll,
    emails: data.emailsList,
    email_title: data.emailTitle,
    email_body: data.notificationType === 'email' ? data.html : data.emailBody,
    email_subject: data.emailSubject,
    push_body: data.pushBody,
    push_subject: data.pushSubject,
    date: data.date.length ? data.date : undefined,
    time: data.time,
    schedule_type: data.type,
    days_of_week: data.daysOfWeek,
    notification_type: data.notificationType,
    discount: data.promotions.map(({ id }) => id),
    agreement: data.agreement,
    users: data.users,
  });
}


export async function uploadEmailImage(blobInfo) {
  const formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());
  return axiosInstance.post(`/agreement_staff/email/upload_image/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
