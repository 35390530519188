import { Grid, TextField, Typography } from "@material-ui/core";
import UploadFile from "./UploadFile";
import { ColorPicker } from "material-ui-color";

import { Box, Button, Center, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Discount } from "../../../components/atoms/Discount";
import { useState } from "react";

const SandboxPhone = ({ colors }) => {
  return (
    <Flex flex="1">
      <VStack
        backgroundImage="url('/phone.png')"
        height="400px"
        backgroundPosition="top-left"
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        width="300px"
        padding="11px 83px 0px 49px"
        position="relative"
        spacing={0}
      >
        <HStack
          padding="20px"
          paddingTop="20px"
          width="100%"
          backgroundColor={colors.menu_color}
          alignItems="center"
          position="relative"
        >
          <FontAwesomeIcon
            style={{ position: "absolute", left: 10 }}
            color={colors.menu_font_color}
            icon={faBars}
          />
          <Center width="100%" left="0px" position="absolute">
            <Image height="30px" src={colors.logo} />
          </Center>
        </HStack>
        <Box
          height="50px"
          width="100%"
          backgroundImage={`linear-gradient(135deg, ${colors.primary_color} 0%, ${colors.secondary_color} 100%)`}
        />
        <Flex flexDirection="column" width="90%" paddingTop="20px">
          <Discount
            title="Teste"
            subTitle="Rua teste 121"
            distance="300m"
            image="https://partiu-rest-s3.s3.amazonaws.com/discount_images/bibi.jpg"
            primaryColor={colors.primary_color}
            secondaryColor={colors.secondary_color}
            secondaryFontColor={colors.secondary_font_color}
          />
          <Button
            marginTop="20px"
            backgroundColor={colors.primary_color}
            color={colors.primary_font_color}
            _hover={{
              backgroundColor: colors.secondary_color,
              color: colors.secondary_font_color,
            }}
          >
            Botão Exemplo
          </Button>
        </Flex>
      </VStack>
    </Flex>
  );
};

export const Step3 = ({ isMobile, state, setState, landing, setLanding }) => {

  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <Grid container>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          required={true}
          source="slug"
          variant="outlined"
          fullWidth
          label="Nome da Página"
          value={landing.slug}
          onChange={(e) => {
            setState({ ...state, slug: e.target.value });
            setLanding({ ...landing, slug: e.target.value });
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Tudo em mínusculo, irá aparecer no link { }.partiu.com.br
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          required={true}
          fullWidth
          variant="outlined"
          source="welcome_text"
          label="Texto da Home"
          value={landing.welcome_text}
          onChange={(e) => {
            setLanding({ ...landing, welcome_text: e.target.value });
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Texto irá aparecer em x lugar
        </Typography>
      </Grid>
      <UploadFile selectedFile={selectedFile} setSelectedFile={setSelectedFile} link={landing.logo} setLink={(link) => {
        setState({ ...state, logo: link });
        setLanding({ ...landing, logo: link })
      }} />
      <SandboxPhone colors={state} />
      <Grid
        xs={12}
        md={4}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "40px",
            color: "#000000",
          }}
        >
          Cor Primaria
        </Typography>
        <ColorPicker
          value={state.primary_color}
          onChange={(value) => {
            setState({ ...state, primary_color: value.css.backgroundColor });
          }} />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Cor de fundo do site
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "40px",
            color: "#000000",
          }}
        >
          Cor de fonte primária
        </Typography>
        <ColorPicker
          value={state.primary_font_color}
          onChange={(value) => {
            setState({ ...state, primary_font_color: value.css.backgroundColor });
          }} />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Cor de fonte primaria
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={4}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "40px",
            color: "#000000",
          }}
        >
          Cor Secundária
        </Typography>
        <ColorPicker
          value={state.secondary_color}
          onChange={(value) => {
            setState({ ...state, secondary_color: value.css.backgroundColor });
          }} />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Cor secundária do site
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "40px",
            color: "#000000",
          }}
        >
          Cor de fonte Secundária
        </Typography>
        <ColorPicker
          value={state.secondary_font_color}
          onChange={(value) => {
            setState({ ...state, secondary_font_color: value.css.backgroundColor });
          }} />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Cor de fonte secundária
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={4}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "40px",
            color: "#000000",
          }}
        >
          Cor do Menu
        </Typography>
        <ColorPicker
          value={state.menu_color}
          onChange={(value) => {
            setState({ ...state, menu_color: value.css.backgroundColor });
          }} />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Cor do menu
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "40px",
            color: "#000000",
          }}
        >
          Cor de fonte do menu
        </Typography>
        <ColorPicker
          value={state.menu_font_color}
          onChange={(value) => {
            setState({ ...state, menu_font_color: value.css.backgroundColor });
          }} />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Cor de fonte do menu
        </Typography>
      </Grid>
    </Grid>
  );
}
