import React from "react";
import {
    AutocompleteInput,
    NumberInput,
    ReferenceInput,
    SimpleForm,
    TextInput
} from "react-admin";

export const MapIntegrationSubscriptionForm = (props) => (
    <SimpleForm {...props}>
        <ReferenceInput
            label="Plano"
            source="plan"
            reference="subscription_plan"
        >
            <AutocompleteInput optionText="display_name" />
        </ReferenceInput>
        <ReferenceInput
            label="Convenio"
            source="agreement"
            reference="agreement"
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source={"plan_slug_integration"} label={"Nome do plano no parceiro"}/>
        <TextInput source={"plan_slug_integration_list"} label={"Nome do plano no parceiro (LISTA - Não preencher o campo de cima, caso esse seja preenchido)"} multiline minRows={10}/>
        <NumberInput source={"price_integration"} label={"Preço"}/>
    </SimpleForm>
);
