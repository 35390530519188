import { Flex } from "@chakra-ui/layout";

export const HoverBox = ({
  children,
  marginRight = "10px",
  marginBottom = "10px",
  flexDirection = "row",
  padding = "0px",
  hover = true,
  ...props
}) => (
  <Flex
    padding={padding}
    flexDirection={flexDirection}
    borderRadius="5px"
    boxShadow="0px 1px 3px rgba(0,0,0,0.1)"
    transition="0.2s"
    justify="flex-start"
    align="flex-start"
    alignItems="stretch"
    width="100%"
    height="100%"
    backgroundColor="#ffffff"
    _hover={
      hover
        ? {
            transform: "translateY(-5px)",
            boxShadow: "0px 5px 10px rgba(0,0,0,0.1)",
          }
        : {}
    }
    {...props}
  >
    {children}
  </Flex>
);
