import React from "react";
import {
  BooleanInput,
  SimpleForm,
  ImageField,
  ImageInput,
  TextInput,
  NumberInput,
} from "react-admin";

export const CategoryForm = (props) => (
  <SimpleForm {...props}>
    <TextInput required={true} source="name" label="Nome" />
    <TextInput
      required={true}
      source="icon"
      label="Ícone"
      helperText="Deve ser no formato fa-icon, procurar em: https://fontawesome.com/search"
    />
    <NumberInput
      source="order"
      label="Prioridade"
      helperText="Valores mais altos aparecerão primeiro"
      fullWidth
    />
  </SimpleForm>
);
