import {
  Box,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getStatus } from "./api";

export const Step4 = ({ state, setState }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleGetStatus = async () => {
    try {
      setIsLoading(true);

      const { data: response } = await getStatus(state.id);

      console.log(response);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state.id) {
      handleGetStatus();
    }
  }, [state]);

  return (
    <VStack alignItems={"flex-start"} spacing={4}>
      <SimpleGrid columns={2} spacing={10}>
        <Box>
          <Heading size={"md"}>Status Android</Heading>
          {isLoading ? (
            <Spinner />
          ) : (
            <VStack>
              <Text></Text>
              <Text></Text>
            </VStack>
          )}
        </Box>
        <Box>
          <Heading size={"md"}>Status IOS</Heading>

          {isLoading ? (
            <Spinner />
          ) : (
            <VStack>
              <Text></Text>
              <Text></Text>
            </VStack>
          )}
        </Box>
      </SimpleGrid>
    </VStack>
  );
};
