import React from "react";
import {
    BooleanInput,
    DateInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from "react-admin";
import { useField } from "react-final-form";

const VoucherBatchCodeCount = () => {
    const { input: { value } } = useField("vouchers")
    const codeCount = React.useMemo(() =>
        value ? value.split("\n").length : 0,
        [value]
    )

    return (
        <p>Achei {codeCount} código(s).</p>
    )
}

export const VoucherBatchForm = (props) => (
    <SimpleForm {...props}>
        <TextInput
            label="Nome"
            fullWidth
            source="name"
        />
        <SelectInput label="Tipo" fullWidth source="type" choices={[
            { name: "Cinema", id: "cinema" }
        ]} />
        <DateInput source="valid_until" label="Válido Até" />
        <TextInput
            multiline
            label="Códigos (um por linha)"
            fullWidth
            source="vouchers"
        />
        <VoucherBatchCodeCount source="vouchers" />
        <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
);
