import React from "react";
import { HoverBox } from "../atoms/HoverBox";
import { Box, Heading } from "@chakra-ui/layout";
import { Subtext } from "../atoms/Subtext";

export const GraphBox = ({
  title,
  subtext,
  children,
  flex = null,
  ...props
}) => (
  <Box flex={flex} width="100%" maxWidth="1000px" {...props}>
    <HoverBox
      marginBottom="20px"
      hover={false}
      padding="20px"
      flexDirection="column"
    >
      <Heading>{title}</Heading>
      {subtext ? <Subtext>{subtext}</Subtext> : <></>}
      {children}
    </HoverBox>
  </Box>
);
