import {
  NotificationCentralProvider,
  useNotificationCentralContext,
} from "./notification-central.context";
import {
  Box,
  Card,
  Flex,
  IconButton,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { FaArrowLeft } from "react-icons/fa";
import { ChooseReceiverStep } from "./Steps/ChooseReceiverStep";
import { ChooseTemplateStep } from "./Steps/ChooseTemplateStep";
import { EditMessageStep } from "./Steps/EditMessageStep";
import { ChooseDateStep } from "./Steps/ChooseDateStep";
import { ConfirmEmailStep } from "./Steps/ConfirmEmailStep";

const steps = [
  { title: "Destinatários", description: "Quem vai receber a mensagem?" },
  { title: "Template", description: "Escolha um template" },
  { title: "Mensagem", description: "Edite a mensagem ou siga" },
  {
    title: "Data e Hora",
    description: "Quando deseja que a mensagem seja enviada?",
  },
  { title: "Confirmação", description: "Confirme aqui o envio" },
];

const NotificationCentral = ({ history }) => {
  const colors = {
    primaryColor: "#db3657",
    primaryFontColor: "#000",
    logo: "/logo.png",
  };

  const { formData } = useNotificationCentralContext();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const previousStep = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const nextStep = () => {
    if (activeStep < steps.length - 1) setActiveStep(activeStep + 1);
  };

  const getMainContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ChooseReceiverStep
            colors={colors}
            onSubmit={nextStep}
            HeaderComponent={Header}
          />
        );
      case 1:
        return (
          <ChooseTemplateStep
            colors={colors}
            onSubmit={nextStep}
            HeaderComponent={Header}
          />
        );

      case 2:
        return (
          <EditMessageStep
            colors={colors}
            onSubmit={nextStep}
            HeaderComponent={Header}
          />
        );

      case 3:
        return (
          <ChooseDateStep
            colors={colors}
            onSubmit={nextStep}
            HeaderComponent={Header}
          />
        );

      case 4:
        return (
          <ConfirmEmailStep
            history={history}
            colors={colors}
            onSubmit={nextStep}
            HeaderComponent={Header}
          />
        );
      default:
        return <Text>Passo nao mapeado</Text>;
    }
  };

  const Header = ({ title }) => (
    <Box display={"flex"} width={"100%"} alignItems={"center"}>
      <IconButton
        isRound={true}
        icon={<FaArrowLeft />}
        aria-label="Voltar"
        onClick={previousStep}
      />
      <Text
        fontSize={"xl"}
        marginRight={"auto"}
        marginLeft={3}
        textAlign={"left"}
        fontWeight={"bold"}
        // borderBottom={`2px solid ${colors.primaryColor}`}
        textColor={colors.primaryFontColor}
      >
        {title}
      </Text>
    </Box>
  );

  return (
    <Stack spacing={10} justifyContent={"flex-start"} direction={"column"}>
      <Card padding={6}>
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={() => activeStep > index && setActiveStep(index)}
            >
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box flexShrink="0" padding={2} width={"100%"}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Card>
      <Card padding={6} width={"100%"} transition="width 2s ease-in-out">
        <Flex
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          flexDirection={"column"}
        >
          {getMainContent()}
        </Flex>
      </Card>
    </Stack>
  );
};

export function NotificationCentralWrapper(props) {
  return (
    <NotificationCentralProvider>
      <NotificationCentral {...props} />
    </NotificationCentralProvider>
  );
}
