import { Box, Grid, TextField, Typography } from "@material-ui/core";

export const Step1 = ({ isMobile, state, setState }) => {
  return (
    <Box>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="Nome"
          type="text"
          required
          fullWidth
          value={state.name}
          onChange={(e) => {
            setState({...state, name: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          A ser escrita a descrição
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="Preço"
          type="number"
          required
          fullWidth
          value={state.price}
          onChange={(e) => {
            setState({...state, price: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          A ser escrita a descrição
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="Email"
          type="email"
          required
          fullWidth
          value={state.email}
          onChange={(e) => {
            setState({...state, email: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          A ser escrita a descrição
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="CNPJ"
          type="text"
          required
          fullWidth
          value={state.cnpj}
          onChange={(e) => {
            setState({...state, cnpj: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          A ser escrita a descrição
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="Site da empresa"
          type="url"
          required
          fullWidth
          value={state.site}
          onChange={(e) => {
            setState({...state, site: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          A ser escrita a descrição
        </Typography>
      </Grid>

      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="Limite de vínculos de CPF"
          type="number"
          fullWidth
          value={state.user_limit}
          onChange={(e) => {
            setState({...state, user_limit: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Limite de vínculos de CPF
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={5}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <TextField
          variant="outlined"
          label="Limite de códigos promocionais"
          type="number"
          fullWidth
          value={state.promocode_limit}
          onChange={(e) => {
            setState({...state, promocode_limit: e.target.value});
          }}
        />
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Limite de códigos promocionais
        </Typography>
      </Grid>
    </Box>
  );
}