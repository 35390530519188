import React from 'react'
import { FunctionField } from 'react-admin'

export function BookTypeField(props) {
    return <FunctionField label="Tipo" render={(record) => record.display_type ?? 'asas'} {...props} />
}

export function BookLevelField(props) {
    return <FunctionField label="Nível" render={(record) => ["Gratuito", "Bronze", "Prata", "Ouro"][record.level]} {...props} />
}

export function BookMonthFeaturedField(props) {
    const monthsFeatured = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
    ]

    return <FunctionField label="Mês" render={(record) => record.month_featured ? `${monthsFeatured[record.month_featured]}/${record.month_featured_year}` : "-"} {...props} />
}