import { Link, Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import { axiosInstance } from "../../axios";

export function TokenButton(props) {
  const record = useRecordContext(props);
  const id = record.id;
  const [isLoading, setIsLoading] = useState(false);

  async function showToken() {
    setIsLoading(true);
    let { data, status } = await axiosInstance.post(
      `/api/v1/agreement_admin/${id}/admin_create/`
    );
    setIsLoading(false);

    if (status !== 200)
      alert(
        "Não foi possível copiar o token desse convênio. " + data.toString()
      );

    alert(data.token);
  }

  return (
    <Button fontSize="10pt" size="sm" isLoading={isLoading} onClick={showToken} colorScheme="green">
      Mostrar Token
    </Button>
  );
}
