import { Button } from "@material-ui/core";
import Web from "@material-ui/icons/Web";
import * as React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useRecordContext
} from "react-admin";

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Nome" alwaysOn />
    <ReferenceInput
      reference="agreement"
      source="agreement"
      label="Convênio"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const AccessLandingButton = (props) => {
  const record = useRecordContext(props);
  let value = record["slug"];

  return (
    <a href={`https://${value}.partiu.com.br`} target="_blank" rel="noreferrer">
      <Button color="primary" startIcon={<Web />}>
        Acessar
      </Button>
    </a>
  );
};

export const LandingList = ({ needsApproval, ...props }) => {
  return (
    <List
      filters={<CompanyFilter />}
      title="Landings"
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="slug" label="Nome da Página" />
        <ReferenceField
          label="Convênio"
          source="agreement"
          reference="agreement"
        >
          <TextField source="name" label="Convênio" />
        </ReferenceField>
        <DateField showTime={true} source="created_at" label="Criado em" />
        <AccessLandingButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};
