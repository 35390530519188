import React from "react";
import {
    SimpleForm,
    TextInput,
    SelectInput,
} from "react-admin";

export const BookSectorForm = (props) => (
    <SimpleForm {...props}>
        <TextInput required={true} source="name" label="Nome" />
        <SelectInput source="type" label="Tipo de Mídia" choices={[{ id: "book", name: "Livro" }, { id: "magazine", name: "Revista" },{ id: "audiobook", name: "Audiobook" }]} />
    </SimpleForm>
);
