import { FormControlLabel, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@material-ui/core";

export const Step4 = ({ isMobile, state, setState }) => {
  const handleChange = (event) => {
    setState({ ...state, integration: event.target.value })
  };

  return (
    <Grid container>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <InputLabel id="select-label">Integração</InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={state.integration}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={"nação"}>Flamengo</MenuItem>
          <MenuItem value={"tim"}>Tim</MenuItem>
          <MenuItem value={"ixc"}>IXC</MenuItem>
          <MenuItem value={"mk"}>MK</MenuItem>
          <MenuItem value={"sgp"}>SGP</MenuItem>
          <MenuItem value={"playhub"}>Playhub</MenuItem>
          <MenuItem value={"easy"}>Easy</MenuItem>
          <MenuItem value={"top"}>Top</MenuItem>
          <MenuItem value={"itnet"}>ItNet</MenuItem>
          <MenuItem value={"az"}>7az</MenuItem>
          <MenuItem value={"youcast!"}>youcast</MenuItem>
          <MenuItem value={"gfsis"}>GFsis</MenuItem>
          <MenuItem value={"neo"}>NEO</MenuItem>
          <MenuItem value={"certirio"}>Certirio</MenuItem>
          <MenuItem value={"isuper"}>iSuper</MenuItem>
          <MenuItem value={"voalle"}>Voalle</MenuItem>
          <MenuItem value={"hubsoft"}>Hubsoft</MenuItem>
        </Select>
        <Typography
          style={{
            fontFamily: "Raleway",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "40px",
            color: "#333333",
          }}
        >
          Integração de Login
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel required control={<Switch
          checked={state.is_default_agreement}
          onChange={(e) => {
            setState({ ...state, is_default_agreement: e.target.checked });
          }} />} label="É Convênio Padrão (Convênio Partiu)" />
      </Grid>
      {["easy", "gfsis", "hubsoft", "ixc", "mk", "sgp", "top"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="integration_host"
            label="Host de Integração"
            value={state.integration_host}
            onChange={(e) => {
              setState({ ...state, integration_host: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            No formato https://integrador.com.br
          </Typography>
        </Grid>}

      {["az", "easy", "ixc", "mk", "sgp", "top"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="integration_token"
            label="Token de Integração"
            value={state.integration_token}
            onChange={(e) => {
              setState({ ...state, integration_token: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            No formato jhziux23a-12312jnash-zkxchzox123-aslkdj123
          </Typography>
        </Grid>}

      {["gfsis", "hubsoft", "youcast", "top", "sgp"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="integration_name"
            label="Nome na Integração"
            value={state.integration_name}
            onChange={(e) => {
              setState({ ...state, integration_name: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            Nome na Integração
          </Typography>
        </Grid>}

      {["easy", "gfsis", "hubsoft", "mk", "top"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="integration_password"
            label="Senha para Integração"
            value={state.integration_password}
            onChange={(e) => {
              setState({ ...state, integration_password: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            Senha para Integração
          </Typography>
        </Grid>}

      {["playhub", "neo"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="integrated_login"
            label="Login integrado"
            value={state.integrated_login}
            onChange={(e) => {
              setState({ ...state, integrated_login: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            Playhub, youcast, neo etc
          </Typography>
        </Grid>}
      {["playhub", "neo"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="idp"
            label="IDP"
            value={state.idp}
            onChange={(e) => {
              setState({ ...state, idp: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            ISP, whitelabel para NEO, Playhub etc
          </Typography>
        </Grid>}

      {["hubsoft"].includes(state.integration) &&
        <Grid
          xs={12}
          md={7}
          display={"flex"}
          flexDirection={"column"}
          style={{
            padding: "8px 16px",
          }}
        >
          <TextField
            required={true}
            fullWidth
            variant="outlined"
            source="integration_special_case"
            label="É caso especial?"
            value={state.integration_special_case}
            onChange={(e) => {
              setState({ ...state, integration_special_case: e.target.value });
            }}
          />
          <Typography
            style={{
              fontFamily: "Raleway",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "40px",
              color: "#333333",
            }}
          >
            Ex: Cliente obrigado a ter pacote Partiu na Hubsoft
          </Typography>
        </Grid>}
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel required control={<Switch
          checked={state.can_use_dataless_integration}
          onChange={(e) => {
            setState({ ...state, can_use_dataless_integration: e.target.checked });
          }} />} label="Pode usar integração sem email e CPF" />
      </Grid>
      <Grid
        xs={12}
        md={12}
        display={"flex"}
        flexDirection={"column"}
        style={{
          padding: "8px 16px",
        }}
      >
        <FormControlLabel required control={<Switch
          checked={state.third_party_login}
          onChange={(e) => {
            setState({ ...state, third_party_login: e.target.checked });
          }} />} label="Usa login de terceiros" />
      </Grid>
    </Grid>
  );
}