import React from "react";
import { FileField, FileInput, NumberInput, SelectInput, SimpleForm, TextInput } from "react-admin";
export const MagazineTemplateForm = (props) => (
    <SimpleForm {...props}>
        <FileField source="file" label="" />
        <FileInput required={true} source="file" label="Arquivo da Revista">
            <FileField source="src" title="title" />
        </FileInput>
        <FileField source="cover" label="" />
        <FileInput required={true} source="cover" label="Capa da Revista">
            <FileField source="src" title="Capa" />
        </FileInput>
        <SelectInput source="reference_month" label={"Mês"} choices={[
            { id: null, name: "" },
            { id: 0, name: "Janeiro" },
            { id: 1, name: "Fevereiro" },
            { id: 2, name: "Março" },
            { id: 3, name: "Abril" },
            { id: 4, name: "Maio" },
            { id: 5, name: "Junho" },
            { id: 6, name: "Julho" },
            { id: 7, name: "Agosto" },
            { id: 8, name: "Setembro" },
            { id: 9, name: "Outubro" },
            { id: 10, name: "Novembro" },
            { id: 11, name: "Dezembro" },
        ]} />
        <SelectInput source="reference_year" label={"Ano"} defaultValue="2024" choices={[
            { id: 2023, name: "2023" },
            { id: 2024, name: "2024" },
            { id: 2025, name: "2025" },
        ]} />
        <NumberInput source="page_to_replace" label="Número da página a ser substituída. Iniciando de 1" />
        <TextInput source="article_title" label="Titulo" />
        <TextInput source="article_content" label="Descrição" fullWidth />
    </SimpleForm>
);
