import React, { createContext, useContext, useEffect, useState } from 'react';

const NotificationCentralContext = createContext({
  formData: {
    type: 'one_time',
    sendToAll: false,
    emailsList: [],
    notificationType: 'email',
    emailSubject: '',
    html: '',
    emailTitle: '',
    emailBody: '',
    pushSubject: '',
    pushBody: '',
    promotions: [],
    date: '',
    daysOfWeek: [],
    time: '',
    agreement: null,
    users: [],
  },
  setFormData: () => {},
});

const NotificationCentralProvider = ({ children }) => {

  const [formData, setFormData] = useState({
    sendToAll: false,
    type: 'one_time',
    emailsList: [],
    notificationType: 'email',
    emailSubject: '',
    emailTitle: '',
    emailBody: '',
    pushSubject: '',
    html: '',
    pushBody: '',
    promotions: [],
    date: '',
    daysOfWeek: [],
    time: '',
    agreement: null,
    users: [],
  });
  useEffect(() => {
    setFormData({
      ...formData,
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    // Step 3: Wrap your component tree with the context provider
    <NotificationCentralContext.Provider value={{ formData, setFormData }}>
      {children}
    </NotificationCentralContext.Provider>
  );
};

function useNotificationCentralContext() {
  return useContext(NotificationCentralContext);
}


export { NotificationCentralProvider, useNotificationCentralContext };
