import React from "react";
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const DefaultPlanForm = (props) => (
  <SimpleForm {...props}>
    <TextInput required label="Nome" source="name" />
    <NumberInput source={"access_amount"} label={"Número de Acessos"} />
    <NumberInput source={"value"} label={"Valor do Plano"} />
    <TextInput
      source={"vindi_page"}
      label={"Página da Vindi"}
      helperText={"Deixe em branco caso não seja um plano para ser vendido"}
    />
    <TextInput
      source={"vindi_token"}
      label={"Token da Vindi"}
      helperText={"Deixe em branco caso não seja um plano para ser vendido"}
    />

    <NumberInput
      source={"audiobook_quantity"}
      label={"Quantidade de Audio Book"}
    />
    <NumberInput source={"book_quantity"} label={"Quantidade de Livros"} />
    <NumberInput
      source={"magazine_quantity"}
      label={"Quantidade de Revistas"}
    />
    <ReferenceInput label="Nível" source="level" reference="level">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </SimpleForm>
);
