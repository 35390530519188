import { useState } from "react";
import { Box, Typography, Grid, Button, CircularProgress } from "@material-ui/core";
import { CameraAltOutlined } from "@material-ui/icons";
import AWS from 'aws-sdk';

export default function UploadFile({
    title = "Logo da Empresa",
    setSelectedFile,
    link,
    setLink,
    imageName = ""
}) {

    const [isLoading, setLoading] = useState(false);


    return (
        <Grid
            flexDirection={"column"}
            style={{ display: "flex", textAlign: "left", padding: "8px 16px", }}
            xs={5}
        >
            <Box
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                height={"174px"}
                position={"relative"}
                style={{
                    borderRadius: "32px",
                    padding: "24px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: "#db3657",
                }}
            >
                <Button
                    component="label"
                    style={{
                        backgroundColor: "transparent",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 99,
                    }}
                >
                    {!isLoading && (
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            onChange={async (e) => {
                                const file = e.target.files[0];
                                setLoading(true)

                                const S3_BUCKET = "partiu-appimages";
                                const REGION = "sa-east-1";

                                AWS.config.update({
                                    accessKeyId: "AKIARGTPCOJMCEQZIEWA",
                                    secretAccessKey: "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",
                                });

                                const s3 = new AWS.S3({
                                    params: { Bucket: S3_BUCKET },
                                    region: REGION,
                                });

                                const params = {
                                    Bucket: S3_BUCKET,
                                    Key: `${imageName}.png`,
                                    Body: file,
                                };

                                const upload = s3
                                    .putObject(params)
                                    .on("httpUploadProgress", (evt) => {
                                        console.log(
                                            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                                        );
                                    })
                                    .promise();

                                await upload.then((err, data) => {
                                    console.log(err, data);
                                    setLink(`https://partiu-appimages.s3.sa-east-1.amazonaws.com/${imageName}.png`)
                                    setSelectedFile(e.target.files[0]);
                                    alert("File uploaded successfully.");
                                    setLoading(false)
                                });

                                setLoading(false)
                                /*
                                if (file) {
                                    // create an image element with that selected file
                                    const img = new Image();
                                    img.src = window.URL.createObjectURL(file);

                                    // as soon as the image has been loaded
                                    img.onload = function () {
                                        setSelectedFile(e.target.files[0]);
                                        setLink(img.src)
                                        //setLink(e.target.files[0])
                                        setLoading(false)
                                    };
                                }*/
                            }}
                            hidden
                        />
                    )}
                </Button>
                {!isLoading && <CameraAltOutlined htmlColor="#FAFAFA" />}
                {isLoading && (
                    <CircularProgress
                        size={24}
                        thickness={4}
                        sx={{ color: "#FFF" }}
                    />
                )}
                {link !== "" && (
                    <img
                        src={link}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            borderRadius: "32px",
                            width: "100%",
                            height: "100%",
                            objectFit: "contain"
                        }}
                        alt="cover"
                    />
                )}
                <Typography
                    style={{
                        fontFamily: "Raleway",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "24px",
                        lineHeight: "40px",
                        color: "#FAFAFA",
                    }}
                >
                    {title}
                </Typography>
            </Box>
        </Grid>
    );
}
