import { useEffect, useState } from "react";
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";

import {
  Box,
  Card,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
} from "@chakra-ui/react";
import { fetchAppData } from "./components/api";
import { Step3 } from "./components/Step3";
import { Step4 } from "./components/Step4";

const steps = [
  {
    title: "Configuração",
    description:
      "Aqui vamos configurar e validar os dados do aplicativo e no final gerar as builds",
  },
  {
    title: "Submissão do App",
    description: "Aqui vamos finalizar a configuração e submeter na loja",
  },
  {
    title: "Configuração da Loja",
    description: "Aqui vamos finalizar a configuração da loja para produção",
  },
  {
    title: "Status do app na Loja",
  },
];

export const FluxCreate = ({ ...props }) => {
  const id = props.match.params.id;

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const [isLoading, setIsLoading] = useState(true);

  const [body, setBody] = useState();

  const handleChangeStep = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep + 1 === steps.length ? 0 : prevActiveStep + 1
    );
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const response = await fetchAppData(id);

          setBody(response.data);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      setIsLoading(false);
    }

  }, [id]);

  return (
    <Stack spacing={2} justifyContent={"flex-start"} direction={"column"}>
      <Card padding={6}>
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={() => (body?.id ? setActiveStep(index) : {})}
            >
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box padding={2}>
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
                {index === 0 && (
                  <a
                    href="https://youtu.be/AxnEJPB8Ikc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Como preencher as informações?
                  </a>
                )}
              </Box>
              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Card>
      <Box width={"100%"} marginTop={4}>
        {activeStep === 0 && !isLoading && (
          <Step1
            state={body}
            changeStep={handleChangeStep}
            setState={setBody}
          />
        )}
        {activeStep === 1 && !isLoading && (
          <Step2 changeStep={handleChangeStep} state={body} setState={setBody} />
        )}
        {activeStep === 2 && !isLoading && <Step3 state={body} setState={setBody} />}
        {activeStep === 3 && !isLoading && <Step4 state={body} setState={setBody} />}
      </Box>
    </Stack>
  );
};
