import React from "react";
import {
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    SelectInput, AutocompleteInput, ReferenceInput,
} from "react-admin";

export const BookCategoryForm = (props) => (
    <SimpleForm {...props}>
        <TextInput required={true} source="name" label="Nome" />
        <SelectInput source="type" label="Tipo de Mídia" choices={[{ id: "book", name: "Livro" }, { id: "magazine", name: "Revista" },{ id: "audiobook", name: "Audiobook" }]} />
        <ReferenceInput label="Setor" reference="book_sector" source="sector">
            <AutocompleteInput source="name" />
        </ReferenceInput>
        <ImageField source="image" label="" />
        <ImageInput source="image" label="Imagem">
            <ImageField source="image" />
        </ImageInput>

    </SimpleForm>
);
