import React from "react";
import {AutocompleteInput, BooleanInput, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput} from "react-admin";

export const PlanForm = (props) => (
    <SimpleForm {...props}>
        <TextInput label="Nome" source="name" />
        <ReferenceInput
            label="Convênio"
            source="agreement"
            reference="agreement"
        >
        <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput label={"Tipo de Plano"} source="target" choices={[
            {id:'company', name:"Para Empresas (SaaS)"},
            {id:'user', name: "Para Usuário (Assinatura)"}
        ]}/>
        <ReferenceInput label="Nível" source="level" reference="level">
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <NumberInput source={"access_amount"} label={"Número de Acessos"}/>
        <NumberInput source={"value"} label={"Valor do Plano"}/>
        <TextInput source={"vindi_page"} label={"Página da Vindi"} helperText={"Deixe em branco caso não seja um plano para ser vendido"}/>
        <TextInput source={"vindi_token"} label={"Token da Vindi"} helperText={"Deixe em branco caso não seja um plano para ser vendido"}/>
        <TextInput source={"slug"} label={"Slug do plano"} helperText={"Slug é o depara entre o recebido pela integração do cliente e esse plano"}/>
        <NumberInput
            source={"audiobook_quantity"}
            label={"Quantidade de Audio Book"}
        />
        <NumberInput source={"book_quantity"} label={"Quantidade de Livros"} />
        <NumberInput
            source={"magazine_quantity"}
            label={"Quantidade de Revistas"}
        />
        <BooleanInput
            source="default"
            label="É plano default?"
        />
    </SimpleForm>
);
