import { Button } from "@material-ui/core";
import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  EditButton,
  BooleanInput,
  Filter,
  TextInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  useRecordContext,
  useDataProvider,
} from "react-admin";
import { axiosInstance } from "../../../axios";
import { CheckNullField } from "../../../components/atoms/CheckNullField";
import { QualityTagField } from "../../../components/atoms/QualityTagField";
import { ToggleInputField } from "../../../components/atoms/ToggleInputField";

const ApproveDenyField = (props) => {
  const { resource } = props;
  const [done, setDone] = React.useState(false);
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();
  const needsApproval =
    ["pending-approval", "pending-training"].indexOf(record.status) !== -1;
  const isPendingApproval = record.status === "pending-approval";
  const nextStage = isPendingApproval ? "pending-terms" : "approved";
  const buttonText = isPendingApproval ? "Aprovar Dados" : "Aprovar Treinameno";

  const handleChange = (event) => {
    dataProvider.update(resource, {
      id: record.id,
      data: { status: nextStage },
    });
  };

  const handleEmail = (event) => {
    setDone(true);
    axiosInstance.post(`/api/v1/company_admin/${record.id}/resend_email/`);
  };

  if (record.status === "pending-terms") {
    return (
      <div style={{ display: "flex" }}>
        <Button
          disabled={done}
          onClick={handleEmail}
          color="primary"
          variant="contained"
        >
          Re-enviar Email de Cadastro
        </Button>
      </div>
    );
  }

  if (needsApproval)
    return (
      <div style={{ display: "flex" }}>
        <Button onClick={handleChange} color="primary" variant="contained">
          {buttonText}
        </Button>
      </div>
    );
  else return <></>;
};

const CompanyFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Nome" alwaysOn />
    <ReferenceInput
      reference="category"
      source="field_of_activity"
      label="Categoria"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      reference="agreement"
      source="agreement"
      label="Exclusivo"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="active" label="Ativo" />
    <SelectInput
      source="actuation"
      choices={[
        { id: "online", name: "E-Commerce" },
        { id: "local", name: "Físico" },
        { id: "both", name: "Ambos" },
        { id: "none", name: "Nenhuma" },
      ]}
      label="Atuação"
    />
    <SelectInput
      source="status"
      choices={[
        { id: "approved", name: "Aprovado" },
        { id: "pending-training", name: "Pendente de Treinamento" },
        { id: "pending-terms", name: "Pendente de Assinatura do Contrato" },
        { id: "pending-approval", name: "Pendente de Aprovação Partiu" },
      ]}
      label="Status"
      alwaysOn
    />
  </Filter>
);

export const CompanyList = (props) => {
  return (
    <List
      filters={<CompanyFilter />}
      title="Empresas"
      sort={{ field: "id", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Nome" />
        <ReferenceField
          label="Categoria"
          source="field_of_activity"
          reference="category"
        >
          <TextField source="name" label="Categoria" />
        </ReferenceField>
        <ReferenceField
          label="Convênio"
          source="creator_agreement"
          reference="agreement"
        >
          <TextField source="name" label="Convênio" />
        </ReferenceField>
        <CheckNullField source="agreement" label="Exclusiva" />
        <TextField source="creator_origin" label="Origem" />
        <TextField source="status" label="Status" />
        <NumberField source="usage" label="Utilizações" />
        <QualityTagField
          source="registration_quality"
          label="Qualidade Cadastro"
        />
        <ToggleInputField source="active" label="Ativo" />
        <DateField source="created_at" label="Criado em" />
        <ApproveDenyField />
        <EditButton />
      </Datagrid>
    </List>
  );
};
