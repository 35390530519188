import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  Img,
  Select,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useRadio,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SubmitStepButton } from "./SubmitStepButton";

import { useNotificationCentralContext } from "../notification-central.context";
// import couponImage from './images/coupon.png';
import shoppingCart from "./images/shopping-cart.png";
import templateCustomizado from "./images/template-customizado.png";
import welcomeImage from "./images/welcome-back.png";
import { allTemplates } from "./templates";

export function ChooseTemplateStep(props) {
  const { HeaderComponent, colors } = props;

  let name = "Partiu";
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templates, setTemplates] = useState();
  const [selectedPromotions, setSelectedPromotions] = useState([]);

  const toast = useToast();
  const { setFormData } = useNotificationCentralContext();

  const { getRadioProps } = useRadioGroup({
    name: "framework",
    defaultValue: "react",
    onChange: (value) => {
      setTemplates(undefined);
      setSelectedTemplate(undefined);
      setSelectedPromotions([]);
      setSelectedCategory(value);
    },
  });

  const getTemplate = (promotions, colors) => {
    if (
      selectedCategory &&
      selectedTemplate &&
      allTemplates[selectedCategory]
    ) {
      const templateObj =
        allTemplates[selectedCategory][selectedTemplate](name);
      return (
        <Template
          text={templateObj.text}
          title={templateObj.title}
          colors={colors}
          promotions={promotions}
        />
      );
    } else if (selectedCategory === "custom") {
      return (
        <HStack justifyContent={"center"} alignItems={"center"}>
          <Card padding={6}>
            <Text>Template no proximo passo vira como vazio</Text>
          </Card>
        </HStack>
      );
    } else
      return (
        <HStack justifyContent={"center"} alignItems={"center"}>
          <Card padding={6}>
            <Text>Nenhum template selecionado</Text>
          </Card>
        </HStack>
      );
  };

  useEffect(() => {
    if (selectedCategory && allTemplates[selectedCategory]) {
      setTemplates(allTemplates[selectedCategory]);
    }
  }, [selectedCategory, templates]);

  const onSubmit = () => {
    if (
      !selectedCategory ||
      (!selectedTemplate && selectedCategory !== "custom")
    ) {
      toast({
        title: "Oops!",
        description: "Selecione um template antes de continuar",
        status: "error",
      });
      return;
    }

    if (!selectedTemplate && selectedCategory === "custom") {
      setFormData((oldState) => ({
        ...oldState,
        emailSubject: "",
        emailTitle: "",
        emailBody: "",
        promotions: selectedPromotions,
      }));
      props.onSubmit();
      return;
    }

    // @ts-ignore
    const templateObj = allTemplates[selectedCategory][selectedTemplate](name);

    setFormData((oldState) => ({
      ...oldState,
      emailSubject: templateObj["subject"],
      emailTitle: templateObj["title"],
      emailBody: templateObj["text"],
      promotions: selectedPromotions,
    }));
    props.onSubmit();
  };

  return (
    <>
      <HeaderComponent title={"Template"} />
      <Stack width={"100%"}>
        <Stack
          direction={["column", "row"]}
          alignItems={"flex-start"}
          spacing={5}
          width={"100%"}
        >
          <Box
            maxWidth={"50%"}
            title="Escolha o template"
            margin={3}
            padding={3}
          >
            <Text fontWeight={"bold"}>
              Já preparamos alguns templates para ajudar na sua comunicação.
            </Text>
            <Text>Selecione uma categoria e um template abaixo</Text>
            <Text marginTop={3}>Categoria:</Text>
            <Wrap>
              <WrapItem padding={1}>
                <RadioCard
                  key={"welcome"}
                  colors={colors}
                  {...getRadioProps({ value: "welcome" })}
                >
                  <VStack height={"100%"}>
                    <img src={welcomeImage} width={"200px"} />
                    <Text fontSize={"12px"}>Bem-vindo</Text>
                  </VStack>
                </RadioCard>
              </WrapItem>
              {/* <Box padding={1} height={'100%'}>
              <RadioCard key={'promotion'} {...getRadioProps({ value: 'promotion' })}>
                <VStack>
                  <img src={couponImage} width={'200px'} />
                  <Text fontSize={'12px'}>Promoções</Text>
                </VStack>
              </RadioCard>
            </Box> */}
              <WrapItem padding={1}>
                <RadioCard
                  key={"specialDates"}
                  colors={colors}
                  {...getRadioProps({ value: "specialDates" })}
                >
                  <VStack height={"100%"}>
                    <img src={shoppingCart} width={"200px"} />
                    <Text fontSize={"12px"}>Datas Comemorativas</Text>
                  </VStack>
                </RadioCard>
              </WrapItem>
              <WrapItem padding={1}>
                <RadioCard
                  key={"custom"}
                  colors={colors}
                  {...getRadioProps({ value: "custom" })}
                >
                  <VStack height={"100%"}>
                    <img src={templateCustomizado} width={"200px"} />
                    <Text fontSize={"12px"}>Customizado</Text>
                  </VStack>
                </RadioCard>
              </WrapItem>
            </Wrap>
            <>
              {selectedCategory !== "custom" && (
                <>
                  <Text marginTop={3}>Template:</Text>
                  <Select
                    variant="filled"
                    placeholder="Selecione um template"
                    marginTop={4}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                  >
                    {templates &&
                      Object.keys(templates).map((template) => (
                        <option value={template}>{template}</option>
                      ))}
                  </Select>
                </>
              )}
              <SubmitStepButton
                colors={colors}
                onSubmit={onSubmit}
                isDisabled={selectedTemplate === null}
              />
            </>
          </Box>
          <VStack flex={1}>
            <Text fontWeight={"bold"}>Preview</Text>
            {getTemplate(selectedPromotions, colors)}
          </VStack>
        </Stack>
      </Stack>
    </>
  );
}

export function Template(props) {
  return (
    <Card title={"Visualização"} maxWidth={"500px"}>
      <VStack
        minHeight="600px"
        backgroundPosition="top-left"
        width="100%"
        spacing={0}
      >
        <Flex
          flexDir="column"
          padding={0}
          justifyContent={"center"}
          alignItems={"center"}
          backgroundColor="white"
          borderRadius="5%"
          wordBreak="break-all"
          width="100%"
        >
          <Text marginBottom="16px">
            Seu Clube de Vantagens tem uma novidade para você:
          </Text>
          <img
            src={props.colors.logo}
            style={{ objectFit: "contain", maxHeight: "80px" }}
          />
          <Divider
            backgroundColor={props.colors.primaryColor}
            padding={"2px"}
            margin={"16px 0"}
          />
          <Flex
            flexDir="column"
            px={2}
            justifyContent="center"
            padding={"16px"}
            align={"center"}
          >
            <Text fontWeight="bold">{props.title}</Text>
            <Text>
              <div
                dangerouslySetInnerHTML={{ __html: props.text }}
                style={{ wordBreak: "normal" }}
              />
            </Text>
            <SimpleGrid columns={2} spacing={10}>
              {props.promotions &&
                props.promotions.map((promotion) => (
                  <Box
                    maxWidth={"400px"}
                    border="1px solid #ddd"
                    borderRadius={"8px"}
                    padding={3}
                    alignItems={"center"}
                    display={"flex"}
                    flexDirection={"column"}
                  >
                    <Box
                      alignItems={"center"}
                      display={"flex"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      flex={1}
                    >
                      <Img
                        src={promotion.image}
                        style={{
                          maxHeight: "300px",
                          maxWidth: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <Text fontWeight={"bold"} marginBottom={4}>
                        {promotion.showName}
                      </Text>
                    </Box>
                    <Button marginTop={"auto"}>Ir para a promoção</Button>
                  </Box>
                ))}
            </SimpleGrid>
          </Flex>
          <Flex padding="16px 0">
            <Button
              backgroundColor={props.colors.primaryColor}
              textColor={props.colors.primaryFontColor}
            >
              Acessar o clube
            </Button>
          </Flex>
          <Flex
            backgroundColor={props.colors.primaryColor}
            textColor={props.colors.primaryFontColor}
            width={"100%"}
            justifyContent={"space-between"}
            minHeight={"100px"}
          >
            <Text
              padding={"4px"}
              width="50%"
              wordBreak={"break-word"}
              textAlign={"center"}
              fontSize={"1.5em"}
              fontWeight={"bold"}
              margin="auto"
            >
              Baixe nosso app
            </Text>
            <Flex
              backgroundColor={props.colors.primaryColor}
              textColor={props.colors.primaryFontColor}
              width={"100%"}
              justifyContent={"space-around"}
            >
              <img
                src={
                  "https://partiu-static.s3.amazonaws.com/badge/googleplay.png"
                }
                style={{ objectFit: "contain", maxWidth: "40%" }}
              />
              <img
                src={
                  "https://partiu-static.s3.amazonaws.com/badge/appstore.png"
                }
                style={{ objectFit: "contain", maxWidth: "40%" }}
              />
            </Flex>
          </Flex>
        </Flex>
      </VStack>
    </Card>
  );
}

function RadioCard(props) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" height={"100%"} display={"block"}>
      <input {...input} />
      <Box
        height={"100%"}
        {...checkbox}
        textAlign={"center"}
        width={"150px"}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "white",
          color: props.colors.primaryColor,
          borderColor: props.colors.primaryColor,
          borderWidth: "3px",
        }}
        _focus={
          {
            // boxShadow: 'outline',
          }
        }
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
}
