import { axiosInstance } from "../../../axios";

export async function createNewApp(params) {
  const formData = new FormData();

  appendFormData(formData, params);
  return axiosInstance.post(`/api/v1/agreement_app_admin/`, formData);
}

export async function editJson(id, body) {
  return axiosInstance.patch(`/api/v1/agreement_app_admin/${id}/`, body);
}

export async function editApp(id, params) {
  const formData = new FormData();

  appendFormData(formData, params);

  return axiosInstance.patch(`/api/v1/agreement_app_admin/${id}/`, formData);
}

export async function runSubmit(id) {
  return axiosInstance.post(`/api/v1/agreement_app_admin/${id}/submit/`);
}

export async function getStatus(id) {
  return axiosInstance.get(`/api/v1/agreement_app_admin/${id}/status/`);
}

export async function updateBuild(id) {
  return axiosInstance.post(`/api/v1/agreement_app_admin/${id}/update/`);
}

export async function runBuild(id) {
  return axiosInstance.post(`/api/v1/agreement_app_admin/${id}/build/`);
}


export async function fetchAppData(id) {
  return axiosInstance.get(`/api/v1/agreement_app_admin/${id}/`);
}

export function appendFormData(formData, data) {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }
}