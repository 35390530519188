import React, { useEffect, useState } from 'react';
import { Button, FormControl, useToast, FormLabel, Input, Spinner, Box, Text } from '@chakra-ui/react';
import { InputImage } from '../../../components/molecules/InputImage';
import axios from 'axios';
import { slugfy } from '../../../utils';

function AgreementApp({ agreement_name }) {

  const toast = useToast()

  const [loading, setLoading] = useState(false)
  const [generating, setGenerating] = useState(false)
  const [name, setName] = useState("")
  const [icon, setIcon] = useState("")
  const [splash, setSplash] = useState("")
  const [agreementUrl, setAgreementUrl] = useState("")
  const [projectId, setProjectId] = useState("")
  const [androidPurchaseToken, setandroidPurchaseToken] = useState("")
  const [iosPurchaseToken, setIosPurchaseToken] = useState("")

  const [generated, setGenerated] = useState(false)

  const [androidUrl, setAndroidUrl] = useState("")

  const verifyExist = async () => {
    try {
      let slug = agreement_name.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .replaceAll(" ", "")
        .toLowerCase()
        .trim()

      const res = await axios.get('https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/actions/artifacts',
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/vnd.github+json",
            "Authorization": "Bearer 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",
            "X-GitHub-Api-Version": "2022-11-28"
          }
        })

      console.log(res)

      if (res.status === 200) {
        let url = ""
        res.data?.artifacts?.map((item) => {
          console.log("item", item, slug, item?.workflow_run?.head_branch, item?.workflow_run?.head_branch === slug)
          if (item?.workflow_run?.head_branch === slug) {
            url = item.archive_download_url
            setAndroidUrl(item.id)
          }
          return true
        })
        console.log(url)

        if (url !== "") {
          setGenerating(false)
          setLoading(false)
          setGenerated(true)
        } else {
          setGenerated(false)
        }
      }
    } catch (err) {
      setGenerated(false)
      setGenerating(false)
      setLoading(false)
    }
  }

  const submitApp = async () => {
    try {
      let slug = agreement_name.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .replaceAll(" ", "")
        .toLowerCase()
        .trim()

      setLoading(true)

      await axios.post('https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/actions/workflows/create-new-app.yml/dispatches',
        {
          ref: "main",
          inputs: {
            name,
            slug,
            bundleIdentifier: `br.com.partiu.${slug}`,
            appBaseUrl: `${slug}.partiu.com.br`,
            icon,
            splash,
            adaptiveIcon: icon,
            projectId,
            iosPurchaseToken,
            androidPurchaseToken
          }

        },
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/vnd.github+json",
            "Authorization": "Bearer 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",
            "X-GitHub-Api-Version": "2022-11-28"
          }
        })

      setGenerating(true)

    } catch (err) {
      setLoading(false)
      toast({
        title: 'Não foi possível gerar o aplicativo',
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
      console.log(err)
    }
  }

  useEffect(() => {
    if (agreement_name) {
      verifyExist()
    }
  }, [agreement_name])

  if (loading) {
    return (
      <Box>
        <Spinner></Spinner>
        {
          generating && (
            <>
              <Text>Gerando aplicativo...</Text>
              <Text>Este processo pode levar de 10 a 20 minutos. Você pode sair desta página e retornar depois do tempo!</Text>
            </>
          )
        }
      </Box>
    )
  }

  if (generated) {
    return (
      <Box>
        <a href="https://youtu.be/VWEPBJB2MzY" target='_blank' rel="noreferrer">Como publicar na google play?</a>
        <br></br>
        <br></br>
        <a href="https://youtu.be/Lm38OhB_r20" target='_blank' rel="noreferrer">Como publicar na apple?</a>
        <br></br>
        <br></br>
        <Text>Aplicativo gerado com sucesso. Para prosseguir com a publicação no android, clique no botão abaixo para fazer download do arquivo.</Text>
        <Text mb={5}>O aplicativo IOS já foi enviado automaticamente para a APPSTORE, para prosseguir com a publicação APPLE, entre no site da appstoreconnect.</Text>

        <Button onClick={async () => {
          console.log("xxxx")

          const res = await axios.get(`https://api.github.com/repos/Partiu-Vantagens/whitelabel-base/actions/artifacts/${androidUrl}/zip`,
            {
              responseType: "blob",
              headers: {
                "Content-Type": "application/json",
                "Accept": "application/vnd.github+json",
                "Authorization": "Bearer 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",
                "X-GitHub-Api-Version": "2022-11-28"
              }
            }).then((response) => {
              const href = URL.createObjectURL(response.data);

              // create "a" HTML element with href to file & click
              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', 'app.zip'); //or any other extension
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
        }}>
          Download Arquivo Android
        </Button>
      </Box>
    )
  }

  return (
    <FormControl>
      <a href="https://youtu.be/AxnEJPB8Ikc" target='_blank' rel="noreferrer">Como preencher as informações?</a>
      <FormLabel mt={5}>
        Nome do aplicativo
        <Input type='text' value={name} onChange={(event) => setName(event.target.value)} />
      </FormLabel>

      <FormLabel mt={5}>
        Icone do aplicativo 1024x1024 (png)<br></br><br></br>
        <InputImage onUpload={(url) => setIcon(url)} imageName={`${slugfy(name)}-icon`} />
      </FormLabel>
      <FormLabel mt={5}>
        Splashscreen 1080x1920 (png)<br></br><br></br>
        <InputImage onUpload={(url) => setSplash(url)} imageName={`${slugfy(name)}-splash`} />
      </FormLabel>
      <FormLabel mt={5}>
        URL do convênio
        <Input type='text' value={agreementUrl} onChange={(event) => setAgreementUrl(event.target.value)} />
      </FormLabel>
      <FormLabel mt={5}>
        Id do Projeto
        <Input type='text' value={projectId} onChange={(event) => setProjectId(event.target.value)} />
      </FormLabel>
      <FormLabel mt={5}>
        Ios Revenue Cat Token
        <Input type='text' value={iosPurchaseToken} onChange={(event) => setIosPurchaseToken(event.target.value)} />
      </FormLabel>
      <FormLabel mt={5}>
        Android Revenue Cat Token
        <Input type='text' value={androidPurchaseToken} onChange={(event) => setandroidPurchaseToken(event.target.value)} />
      </FormLabel>
      <Button colorScheme="green" mt={5} onClick={submitApp} disabled={!agreementUrl || !splash || !icon || !name || !projectId || !iosPurchaseToken || !androidPurchaseToken}>Gerar aplicativo</Button>
    </FormControl>
  )
}

export default AgreementApp;