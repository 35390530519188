import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  TextInput,
} from "react-admin";
import { fromForm } from "./fromForm";

function getReferenceField(field) {
  return <ReferenceField {...field}>{field.field}</ReferenceField>;
}

export function fromFields(
  FormElement,
  fields,
  sort = { field: "id", order: "DESC" }
) {
  function FilterComponent(props) {
    return (
      <Filter {...props}>
        <TextInput source="search" label="Busca..." alwaysOn />

        {fields
          .filter((f) => f.input)
          .map((f) => {
            const props = { ...f, input: undefined  };
            const input = f.input

            if (f.reference) {
              return <ReferenceInput {...props} >{input}</ReferenceInput>;
            }

            return input;
          })}
      </Filter>
    );
  }

  return {
    list: (props) => {
      return (
        <List sort={sort} filters={<FilterComponent />} {...props}>
          <Datagrid>
            {fields.map((f) => {
              const Field = { ...f.field };
              let _f = { ...f };
              let children = f.children;

              if (f.render) return f.render

              delete _f.field;

              if (_f.reference) {
                return <ReferenceField {..._f} children={f.field} />;
              }

              return <Field {..._f}>{children}</Field>;
            })}
            <EditButton />
          </Datagrid>
        </List>
      );
    },
    ...fromForm(FormElement),
  };
}
