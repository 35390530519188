import {
  Center,
  CircularProgress,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useState } from "react";
import { useRecordContext } from "react-admin";
import { useField } from "react-final-form";

const containerStyle = {
  width: "400px",
  height: "300px",
};

export function MapInput(props) {
  const toast = useToast();
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const record = useRecordContext(props);
  const {
    input: { onChange: onLatitudeChange },
  } = useField("latitude");
  const {
    input: { onChange: onLongitudeChange },
  } = useField("longitude");
  let lat = record["latitude"];
  let lng = record["longitude"];
  let street = record["street"];
  let postal_code = record["postal_code"];
  let state = record["state"];
  let city = record["city"];
  let country = record["country"];
  let number = record["number"];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB5tXrkiapXmzmvkkW0-7MQvfx1LL9VScQ",
  });

  const onLoad = function callback(map) {
    if (lat && lng) {
      map.setCenter(new window.google.maps.LatLng(lat, lng));
      setLocation(new window.google.maps.LatLng(lat, lng));

      return;
    }
    let geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        address: `${number}, ${street}, ${city}, ${state}, ${postal_code}, ${country}`,
      },
      function (results, status) {
        if (status === "OK") {
          const location = results[0].geometry.location;

          map.setCenter(location);
          setLocation(location);
          onLatitudeChange(location.lat());
          onLongitudeChange(location.lng());
        } else {
          toast({
            title: "Oops!",
            description: "Não carregar o mapa, entre em contato com o suporte.",
            status: "error",
          });
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <VStack justify="flex-start" align="flex-start">
      <Text paddingBottom="20px">
        É possível ajustar a localização arrastando o pino vermelho no centro do
        mapa.
      </Text>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}
          zoom={17}
          onLoad={onLoad}
        >
          <Marker
            position={location}
            draggable={true}
            onDragEnd={(e) => {
              onLatitudeChange(e.latLng.lat());
              onLongitudeChange(e.latLng.lng());
            }}
          />
        </GoogleMap>
      ) : (
        <Center>
          <CircularProgress isIndeterminate />
        </Center>
      )}
    </VStack>
  );
}
