import React from "react";
import {
    ArrayInput,
    AutocompleteInput, BooleanInput, DateInput,
    ReferenceInput,
    SimpleForm, SimpleFormIterator,
    TextInput
} from "react-admin";

export const SubscriptionForm = (props) => (
    <SimpleForm {...props}>
        <ReferenceInput
            label="Usuário"
            source="user"
            reference="user"
            allowNull={true}
            allowEmpty={true}
            helperText={"Esse campo pode ficar vazio caso CPF esteja preenchido"}
        >
            <AutocompleteInput optionText="full_name" />
        </ReferenceInput>
        <TextInput source={"cpf"} helperText={"Esse campo pode ficar vazio caso Usuário esteja preenchido"} label={"CPF"}/>
        {/* <ArrayInput source={"extra_cpfs"}>
            <SimpleFormIterator>
                <TextInput label="" />
            </SimpleFormIterator>
        </ArrayInput> */}
        <ReferenceInput
            label="Plano"
            source="plan"
            reference="subscription_plan"
        >
            <AutocompleteInput optionText="display_name" />
        </ReferenceInput>
        <DateInput label={"Válido Até"} source={"valid_until"} allowNull={true} helperText={"Deixe vazio caso não tenha validade."}/>
        <BooleanInput source="active" label="Ativo" />
    </SimpleForm>
);
