import React from "react";

export function Hint({ children }) {
  return (
    <p
      style={{
        marginTop: -20,
        marginBottom: 10,
        width: "100%",
        fontSize: 13,
        color: "rgba(0,0,0,0.6)",
      }}
    >
      {children}
    </p>
  );
}
