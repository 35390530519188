import { Avatar } from "@chakra-ui/avatar";
import { Box } from "@chakra-ui/layout";
import {
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/modal";
import { CircularProgress } from "@chakra-ui/progress";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDataProvider, useGetOne } from "react-admin";
import { axiosInstance } from "../../axios";
import { Subtext } from "../atoms/Subtext";

function UserAttribute({ title, value, fontSize = "20px" }) {
  return (
    <VStack alignItems="center">
      <Heading fontSize={`${fontSize} !important`}>{value}</Heading>
      <Text fontSize="12px" textTransform="lowercase">
        {title}
      </Text>
    </VStack>
  );
}

function UserInfo({ title, value }) {
  return (
    <VStack alignItems="flex-start">
      <Heading fontSize={"12px !important"}>{title}</Heading>
      <Text fontSize="14px" textTransform="lowercase">
        {value ? value : "Não Informado"}
      </Text>
    </VStack>
  );
}

function UserFavoriteStore({ title, subtitle, usage, logo }) {
  return (
    <HStack display="flex" spacing={10} width="100%">
      <Avatar name={title} objectFit="contain" src={logo} />
      <VStack flex="2" alignItems="flex-start">
        <Heading fontSize="12px !important">{title}</Heading>
        <Text fontSize="12px" textTransform="lowercase">
          {subtitle}
        </Text>
      </VStack>
      <Heading flex="1" marginLeft="auto">
        {usage}
      </Heading>
    </HStack>
  );
}

export function UserModal({ id, isOpen, onClose }) {
  const dataProvider = useDataProvider();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (id == null) return;
    axiosInstance
      .get(`/api/v1/agreement/user/${id}/report/`)
      .then(({ data }) => setData(data));
  }, [id]);

  if (data == null)
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay
          backdropFilter="blur(5px)"
          style={{ "-webkit-backdrop-filter": "blur(5px)" }}
        />
        <ModalContent>
          <ModalBody padding="20px">
            <VStack alignItems="center" spacing={5}>
              <Heading>Carregando Dados...</Heading>
              <CircularProgress isIndeterminate />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay
        backdropFilter="blur(5px)"
        style={{ "-webkit-backdrop-filter": "blur(5px)" }}
      />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="40px">
          <VStack spacing={5}>
            <VStack width="100%">
              <Avatar name={data.full_name} src={data.image} size="xl" />
              <Heading>{data.full_name}</Heading>
              <Subtext>
                Membro desde{" "}
                {new Date(data.created_at).toLocaleDateString("pt-BR")}
              </Subtext>
              <HStack
                paddingTop="20px"
                width="100%"
                alignItems="flex-start"
                spacing={10}
              >
                <VStack spacing={5} alignItems="flex-start">
                  <UserInfo title="Email" value={data.email} />
                  <UserInfo title="Telefone" value={data.telephone} />
                </VStack>
                <VStack alignItems="flex-start">
                  <UserInfo title="Cidade" value={data.last_city} />
                </VStack>
              </HStack>
            </VStack>
            <VStack width="100%">
              <Divider />
              <HStack>
                <UserAttribute
                  title="Utilizações"
                  value={data.usage_online + data.usage_local}
                />
                <UserAttribute
                  title="Utilizações Online"
                  value={data.usage_online}
                />
                <UserAttribute
                  title="Utilizações Local"
                  value={data.usage_local}
                />
                <UserAttribute
                  title="Segmento Favorito"
                  value={
                    data.favorite_field.length > 0
                      ? data.favorite_field[0][0]
                      : "Nenhum"
                  }
                  fontSize="12px"
                />
              </HStack>
              <Divider />
            </VStack>
            <VStack alignItems="flex-start" width="100%">
              <Heading paddingBottom="20px">Empresas Favoritas</Heading>
              {data.favorite_companies.map((s) => (
                <UserFavoriteStore
                  title={s[0]}
                  subtitle={s[1]}
                  usage={s[2]}
                  logo={s[3]}
                />
              ))}
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
