import { BooleanField, DateField, NumberField, Resource, TextField } from "react-admin";
import { Apps } from "../../enums";
import { fromFields } from "../../components/core/fromFields";
import { VindiLogForm } from "./vindi_log/VindiLogForm";
import { NetworkCheck } from "@material-ui/icons";
import { SurfRechargeForm } from "./surf_recharges/SurfRechargeForm";

export const integrationsApp = [
    <Resource
        id="vindilog"
        options={{ label: "Logs da Vindi", app: Apps.integrations }}
        icon={NetworkCheck}
        name="vindilog"
        {...fromFields(VindiLogForm, [
            { source: "id", field: NumberField, label: "ID" },
            { source: "user", reference: "user", field: <TextField source="full_name" />, label: "Usuário" },
            { source: "created_at", field: DateField, label: "Criado em" },
            { source: "error", field: BooleanField, label: "Erro" },
            { source: "handled", field: BooleanField, label: "Recebido" },
            { source: "fully_processed", field: BooleanField, label: "Processado" },

        ])}
    />,
    <Resource
        id="surf_recharges"
        options={{ label: "Recargas da Surf", app: Apps.integrations }}
        icon={NetworkCheck}
        name="surf_recharges"
        {...fromFields(SurfRechargeForm, [
            { source: "id", field: NumberField, label: "ID" },
            { source: "document", field: TextField, label: 'Documento' },
            { source: "cellphone", field: TextField, label: 'Telefone' },
            { source: "created_at", field: DateField, label: "Criado em" },
            { source: "updated_at", field: DateField, label: "Atualizado em" },
            { source: "reference_date", field: DateField, label: "Recarga em" },
            { source: "audiobook_quantity", field: NumberField, label: "Quantidade de Audiobooks" },
            { source: "book_quantity", field: NumberField, label: "Quantidade de livros" },
            { source: "amount", field: NumberField, label: "Valor" },
            { source: "used", field: BooleanField, label: "Usado?" },

        ])}
    />


]