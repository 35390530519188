import React from "react";
import { Avatar, HStack, Text, VStack } from "@chakra-ui/react";
import { Heading } from "@chakra-ui/layout";
import { GraphBox } from "../../molecules/GraphBox";

const User = ({ avatar, name, usage, onClick }) => (
  <HStack
    transition="0.2s"
    _hover={{ transform: "translateX(15px)" }}
    cursor="pointer"
    onClick={onClick}
  >
    <Avatar name={name} src={avatar} />
    <VStack spacing={1} alignItems="flex-start">
      <Heading>{name}</Heading>
      <Text fontSize="14px">{usage} utilizações</Text>
    </VStack>
  </HStack>
);

export const UserRankGraph = ({ data, onClick }) => {
  return (
    <GraphBox
      maxWidth="320px"
      flex="1"
      title="Ranking Usuários"
      subtext="Aperte nos usuários para ver mais."
    >
      <VStack spacing={5} marginTop="20px" alignItems="flex-start">
        {data.map((u) => (
          <User
            onClick={() => onClick(u[0])}
            avatar={u[3]}
            name={u[1]}
            usage={u[2]}
          />
        ))}
      </VStack>
    </GraphBox>
  );
};
