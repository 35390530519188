import React from "react";
import {
  NumberInput,
  SimpleForm,
  TextInput,
} from "react-admin";

export const LevelForm = (props) => (
  <SimpleForm {...props}>
    <TextInput label="Nome" source="name" />
    <NumberInput source={"rank"} label={"Rank"} />
  </SimpleForm>
);
