import {
  Box,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { AutocompleteInput, ReferenceInput, SimpleForm } from 'react-admin';
import { useQuery } from 'react-query';
import { axiosInstanceV2 } from '../../axios';


function AgreementDefaultPlan() {

  const getDefaultPlans = async () => {
      return axiosInstanceV2.get(`/default_plan_admin`).then((response) => response.data);
  }
  const booksQuery = useQuery(["default_plan_admin"],() => getDefaultPlans(), {
    retry: 0,
    enabled: true,
  });

  return (
    <Box backgroundColor="">
      <VStack my={4} width={"100%"} justifyContent={"flex-start"} alignItems={"flex-start"} >
        <Text fontSize='2xl' fontWeight={"bold"}>Criação de Planos em massa</Text>
        <Box pt={3}>
          <SimpleForm >
            <ReferenceInput label="Nível" source="level" reference="level">
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Convenio" source="agreement" reference="agreement">
              <AutocompleteInput optionText="name" />
            </ReferenceInput>
          </SimpleForm>
        </Box>
      </VStack>
    </Box>
  )
}

export default AgreementDefaultPlan;