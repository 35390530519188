import React from "react";
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

export const PromocodeBatchForm = (props) => (
  <SimpleForm {...props}>
    <TextInput label="Nome do Lote (deve ser único)" source="name" />
    <ReferenceInput
      label="Convênio"
      source="agreement"
      reference="agreement"
      isRequired
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="quantity" label="Quantidade de Códigos" />
    <BooleanInput source="active" label="Ativo" defaultValue={true} />
  </SimpleForm>
);
