import React from "react";
import { GraphBox } from "../../molecules/GraphBox";
import { GRAPH_COLORS, MONTH } from "../../../constants";
import { Bar, Line } from "react-chartjs-2";
import { useCheckMobile } from "../../../utils";

export const WeekdayUsageGraph = ({ weekdayUsage, ...style }) => {
  const isMobile = useCheckMobile();
  const _weekdayUsage = weekdayUsage
    .sort((a, b) => a.created_at__week_day > b.created_at__week_day)
    .map((v) => v.usage);

  return (
    <GraphBox title="Utilização x Dia da semana" {...style}>
      <Bar
        width="100%"
        height={isMobile ? "100px" : "60px"}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
        data={{
          labels: [
            "Segunda",
            "Terça",
            "Quarta",
            "Quinta",
            "Sexta",
            "Sábado",
            "Domingo",
          ],
          datasets: [
            {
              label: "Utilização",
              data: _weekdayUsage,
              borderColor: GRAPH_COLORS,
              backgroundColor: GRAPH_COLORS,
              borderWidth: 1,
            },
          ],
        }}
      />
    </GraphBox>
  );
};
