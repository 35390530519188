import { DateField, Resource, TextField } from "react-admin";
import { Devices, DeviceHub } from "@material-ui/icons"
import { Apps } from "../../enums";
import { CommunicationList } from "./communication/CommunicationList";
import { CommunicationCreate } from "./communication/CommunicationCreate";
import { fromFields } from "../../components/core/fromFields";
import { CommunicationBatchTabbedForm } from "./communication/CommunicationBatchTabbedForm";

export const communicationApp = [
    <Resource
        id="communication"
        options={{ label: "Comunicação", app: Apps.communication }}
        icon={Devices}
        name="communication"
        list={CommunicationList}
        create={CommunicationCreate}
    />,
    <Resource
        id="communication_batch"
        options={{ label: "Lotes de Comunicação", app: Apps.communication }}
        icon={DeviceHub}
        name="communication_batch"
        {...fromFields(CommunicationBatchTabbedForm, [
            { source: "name", field: TextField, label: "Nome" },
            { source: "starts_at", field: DateField, label: "Começa em" },
            { source: "ends_at", field: DateField, label: "Termina em" },
        ])}
    />
]