import { Flex, Image, VStack,Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

// import { Container } from './styles';

function Book({onClick, cover, title, id, createdAt }) {
  return (
    <Link to={"/books-dashboard/" + id}>
      <VStack onClick={onClick} align="flex-start" width="100%" cursor="pointer" maxWidth={{ base: "165px", lg: "205px", "2xl": "260px" }}>
        <Flex
            width="100%"
            boxSizing="border-box"
            overflow="hidden"
            backgroundColor="white"
            borderRadius="5px"
            height={{ base: "270px", lg: "310px", "2xl": "410px" }}
        >
            <Image
                src={cover}
                objectFit={'cover'}
                objectPosition={"center"}
                transition="0.5s"
                width="100%"
                height="100%"
                backgroundColor="white"
                flexShrink="0"
                _hover={{
                    transform: "scale(1.05)"
                }}
            />
        </Flex>
        <VStack align="flex-start" paddingTop="5px" spacing={1}>
          <Text fontWeight="bold" color="gray" wordBreak={"break-word"}>{id}</Text>
          <Text fontWeight="bold" fontSize={{ base: "9.5pt", lg: "12pt" }} wordBreak={"break-word"}>{title?.toUpperCase()}</Text>
          <Text opacity="0.8" fontSize={{ base: "8pt", lg: "10pt" }}
              textTransform="capitalize">{createdAt?.toLowerCase()}</Text>
        </VStack>
      </VStack>
    </Link>
  );
}

export default Book;