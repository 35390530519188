import {
  Box,
  Button,
  Heading,
  ListItem,
  OrderedList,
  SimpleGrid,
  useToast,
  Checkbox,
  VStack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { get, useForm } from "react-hook-form";
import { editJson } from "./api";

export const Step3 = ({ state, setState }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const id = state?.id;

  const { register, getValues } = useForm({
    defaultValues: {
      ...state.checklist_metadata,
    },
  });

  const toast = useToast();

  const handleSaveApp = async () => {
    try {
      setIsLoading(true);

      let formData = getValues();

      try {
        if (id) {
          let body = {
            checklist_metadata: formData,
          };

          const { data: response } = await editJson(id, body);

          setState(response);
        }
      } catch (error) {
        throw new Error("Erro ao sbmeter o app");
      }

      toast({
        title: "App salvo com sucesso!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      return;
    } catch (error) {
      toast({
        title: error.message,
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form style={{ width: "100%" }}>
      <VStack alignItems={"flex-start"} spacing={4}>
        <SimpleGrid columns={2} spacing={10}>
          <Box>
            <Heading size="lg">Android</Heading>
            <OrderedList>
              <ListItem fontWeight={"bold"}>
                Detalhes do App -> Aumente o número de usuárioos -> Presença da
                Loja -> Páginas "Detalhes do App"
              </ListItem>
              <OrderedList>
                <ListItem>
                  <Checkbox
                    {...register("android_appname", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Nome do App</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_desc", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Breve descrição</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_desccompleta", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Descrição completa</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_icon", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Ícone do aplicativo</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_phoness", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Capturas de tela do telefone
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_tabletss", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Capturas de tela do tablet de 7 pol.
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_tablet2ss", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Capturas de tela de tablet de 10"
                    </Text>
                  </Checkbox>
                </ListItem>
              </OrderedList>
              <ListItem fontWeight={"bold"}>Configuração da loja</ListItem>
              <OrderedList>
                <ListItem>
                  <Checkbox
                    {...register("android_category", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Categoria do app: App</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_category2", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Categoria: Estilo de vida
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_email", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Endereço de e-mail: ajuda@partiu.com.br
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_site", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Site: ex: "https://slug.partiu.com.br"
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_market", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Ativar O Marketing externo
                    </Text>
                  </Checkbox>
                </ListItem>
              </OrderedList>
              <ListItem fontWeight={"bold"}>
                Monitorar e melhorar -> Política e programas -> Conteúdo do app
              </ListItem>
              <OrderedList>
                <ListItem>
                  <Checkbox
                    {...register("android_instructions", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Acesso de Apps:
                      <br />
                      Nome da instrução: Instruções para acessar o app
                      <br />
                      Nome de usuário e senha:
                      <br />
                      Email: testeslug@partiu.com.br
                      <br />
                      Senha: Teste@123
                      <br />
                      Instruções: Para se cadastrar com assinatura o usuário
                      será solicitado para informar os dados: nome completo,
                      e-mail, celular, CPF e uma senha forte.
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Text textTransform={"none"} fontWeight={"bold"}>
                    Segurança dos dados
                  </Text>
                  <OrderedList>
                    <ListItem>
                      <Checkbox
                        {...register("android_datacolection", {
                          required: true,
                        })}
                        size="md"
                      >
                        <Text textTransform={"none"}>
                          O app coleta ou compartilha algum dos tipos de dados
                          do usuário obrigatórios?: Marcar "Sim"
                        </Text>
                      </Checkbox>
                    </ListItem>
                    <ListItem>
                      <Checkbox
                        {...register("android_deleteaccount", {
                          required: true,
                        })}
                        size="md"
                      >
                        <Text textTransform={"none"}>
                          Quais dos seguintes métodos de criação de contas são
                          oferecidos pelo app?: Marcar "Nome de usuário, senha e
                          outras autenticações"
                        </Text>
                      </Checkbox>
                    </ListItem>
                    <ListItem>
                      <Checkbox
                        {...register("android_deleteaccountsite", {
                          required: true,
                        })}
                        size="md"
                      >
                        <Text textTransform={"none"}>
                          Preencher URL para exclusão de contas:
                          "https://slug.partiu.com.br/contato
                        </Text>
                      </Checkbox>
                    </ListItem>
                    <ListItem>
                      <Checkbox
                        {...register("android_datacoletioninstruction", {
                          required: true,
                        })}
                        size="md"
                      >
                        <Text textTransform={"none"}>
                          Tipos de dados: Marcar <br />
                          1. Identificadores do dispositivo e outros <br />
                          2. Informações de pagamento do usuário
                          <br />
                          3. Histórico de compras
                          <br />
                          4. Nome <br />
                          5. Endereço de e-mail
                          <br />
                          6. Ids de Usuários <br />
                          7. Numero de Telefone <br />
                          8. Caso app seja Club: Local aproximado e Local exato
                        </Text>
                      </Checkbox>
                    </ListItem>
                    <ListItem>
                      <Checkbox
                        {...register("android_datacoletioninstruction2", {
                          required: true,
                        })}
                        size="md"
                      >
                        <Text textTransform={"none"}>
                          Para todos os Itens anteriores Marcar
                          <br />
                          1. Coletados
                          <br />
                          2. Sim, os dados coletados são processados de maneira
                          efêmera
                          <br />
                          3. Os usuários podem escolher se os dados são
                          coletados
                          <br />
                          4. Funcionalidade do app
                        </Text>
                      </Checkbox>
                    </ListItem>
                  </OrderedList>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_appsaude", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}> App Saúde: Não</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_finance", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Recursos financeiros: Habilitar apenas "Pagamentos com
                      smartphone e carteiras digitais"
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_news", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}> Apps de notícias: não</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_olderpublic", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Público-alvo e conteúdo: Maiores de 18 anos
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_classification", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Classificações de conteúdo: ajuda@partiu.com.br, Categoria
                      "Todos" e Seguir os passos para obter uma classificação
                      livre
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_ads", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Anúncios: Não, meu app não tem anúncios
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("android_privacyurl", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      {" "}
                      Política de privacidade:
                      https://slug.partiu.com.br/legal/politica-de-privacidade
                    </Text>
                  </Checkbox>
                </ListItem>
              </OrderedList>
            </OrderedList>
          </Box>
          <Box>
            <Heading size="lg">IOS</Heading>
            <OrderedList>
              <ListItem fontWeight={"bold"}>Paginá Inicial</ListItem>
              <OrderedList>
                <ListItem>
                  <Checkbox
                    {...register("ios_phoness", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Screenshots Iphone 6.5</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_ipadss", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Screenshots Ipad 13</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_ipaddesc", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Descrição</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_keywords", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Palavras Chave: Biblioteca, Clube de vantagen, Nome do app
                      e etc
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_support", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Url de Suporte: https://slug.ebooks.app.br/contato
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_copy", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Copyright: © Copyright 2024 - Partiu - Todos os direitos
                      reservados
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_reviewdata", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Informação de Revisão
                      <br /> Login-in: Email: testeslug@partiu.com.br
                      <br /> Login-in: Senha: Teste@123
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_contact", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Contato:
                      <br /> Vanger Andrade
                      <br /> +5521974204416
                      <br /> ajuda@partiu.com.br
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_notes", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Notas: Acessar com email e senha. Para testar compras no
                      aplicativo Após fazer login, você vai em "menu", escolhe
                      "Combos (Upgrade ou Contrato), então você pode usar
                      compras no aplicativo
                    </Text>
                  </Checkbox>
                </ListItem>
              </OrderedList>
              <ListItem fontWeight={"bold"}>Privacidade do App</ListItem>
              <OrderedList>
                <ListItem>
                  <Checkbox
                    {...register("ios_privacy", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      URL da Política de Privacidade:
                      https://slug.partiu.com.br/legal/politica-de-privacidade
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_datacolection", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Coleta de dados
                      <br /> Sim, o app coleta dados do usuário
                      <br /> Nome
                      <br /> E-mail
                      <br /> Telefone
                      <br /> Outros
                      <br /> Pagamentos
                      <br /> Localização precisa
                      <br /> Localização aproximada
                      <br /> Email ou Texto Mensagens
                      <br /> Compras
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_datacolection2", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      A cada item anteriores marcar
                      <br /> Funcionalidade do aplicativo
                      <br /> Personalização de produto
                      <br /> Sim, as informações de pagamento coletadas neste
                      aplicativo estão vinculadas à identidade do usuário
                    </Text>
                  </Checkbox>
                </ListItem>
              </OrderedList>

              <ListItem fontWeight={"bold"}>Informações do App</ListItem>
              <OrderedList>
                <ListItem>
                  <Checkbox
                    {...register("ios_appname", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Nome do App</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_appcategory", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>Categoria</Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_classification", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Classificação de Idade: Colocar tudo Nenhum{" "}
                    </Text>
                  </Checkbox>
                </ListItem>
                <ListItem>
                  <Checkbox
                    {...register("ios_owner", {
                      required: true,
                    })}
                    size="md"
                  >
                    <Text textTransform={"none"}>
                      Direitos de conteúdos: Colocar não
                    </Text>
                  </Checkbox>
                </ListItem>
              </OrderedList>
            </OrderedList>
          </Box>
        </SimpleGrid>
        <Button
          isLoading={isLoading}
          type="button"
          onClick={() => handleSaveApp()}
        >
          Salvar
        </Button>
      </VStack>
    </form>
  );
};
