import React from "react";
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { AutoLoginButton } from "../../components/atoms/AutoLoginButton";
import { ToggleInputField } from "../../components/atoms/ToggleInputField";
import { TokenButton } from "../../components/atoms/TokenButton";

const AgreementFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Nome" alwaysOn />
    <ReferenceInput source="plan" reference="plan" alwaysOn label="Catalogo">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput alwaysOn source="subscribed" label="SaaS" />
    <BooleanInput source="active" label="Ativo" />
  </Filter>
);

export const AgreementList = (props) => {
  return (
    <List
      filters={<AgreementFilter />}
      sort={{ field: "user_count", order: "DESC" }}
      title="Convênios"
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ImageField source="logo" label="Logo" />
        <TextField source="name" label="Nome" />
        <NumberField source="usages" label="Utilizações" />
        <NumberField source="checkins" label="Check-Ins" />
        <NumberField source="user_count" label="Usuários Ativos" />
        <ReferenceField reference="plan" source="plan" label="Catalogo">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField reference="agreement_plans" source="paid_plan" label="Plano">
          <TextField default="Padrão" source="name" />
        </ReferenceField>
        <ToggleInputField source="active" label="Ativo" />
        <DateField source="created_at" label="Criado em" />
        <TokenButton />
        <AutoLoginButton
          label="Visão Convênio"
          source="et"
          url="https://dashboard.partiu.com.br/"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
