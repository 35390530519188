import {
  Box,
  CircularProgress,
  Flex,
  Heading,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { HoverBox } from "../atoms/HoverBox";

export const StatisticCard = ({
  icon,
  title,
  description,
  loading = false,
  children,
  bigNum = true,
  infoText,
  formatOptions,
}) => {
  const primaryColor = "rgb(229, 58, 53)";
  const primaryFontColor = "white";

  return (
    <HoverBox alignItems="center" padding="0px 20px">
      <Flex
        minWidth="50px"
        minHeight="50px"
        backgroundColor={primaryColor}
        justifyContent="center"
        alignItems="center"
        borderTopLeftRadius="5px"
        borderBottomLeftRadius="5px"
        borderRadius="500px"
      >
        <FontAwesomeIcon color={primaryFontColor} size="lg" icon={icon} />
      </Flex>
      <Flex
        position="relative"
        paddingTop="20px"
        paddingLeft="10px"
        marginLeft="10px"
        direction="column"
        paddingBottom="20px"
        alignItems="flex-end"
        width="100%"
      >
        {infoText ? (
          <Box
            backgroundColor={primaryColor}
            width="25px"
            height="25px"
            borderRadius="50px"
            position="absolute"
            right="-20px"
            top="15px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Tooltip label={infoText}>
              <span>
                <FontAwesomeIcon
                  size="sm"
                  color={primaryFontColor}
                  icon={faQuestion}
                />
              </span>
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}
        {loading ? (
          <CircularProgress size="30px" isIndeterminate color="green.300" />
        ) : bigNum ? (
          <Text
            alignSelf="flex-end"
            fontFamily="Montserrat"
            fontSize="18px"
            fontWeight="600"
          >
            {new Intl.NumberFormat("pt-br", formatOptions).format(description)}
          </Text>
        ) : (
          <Text flex="1">{description}</Text>
        )}
        <Heading
          flex="1"
          fontSize="14px"
          fontWeight="400"
          textTransform="lowercase !important"
        >
          {title}
        </Heading>
        {children}
      </Flex>
    </HoverBox>
  );
};
