import React from "react";
import {
    SimpleForm,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
} from "react-admin";

export const BookContributorForm = (props) => (
    <SimpleForm {...props}>
        <TextInput required={true} source="name" label="Nome" />
        <TextInput required={true} source="name_inverted" label="Nome Invertido" />
        <ReferenceInput
            reference="book"
            source="book"
            label="Livro"
            alwaysOn
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
    </SimpleForm>
);
