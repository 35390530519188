import React from "react";
import {
  BooleanInput,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
} from "react-admin";

export const PromocodeForm = (props) => (
  <SimpleForm {...props}>
    <ReferenceInput
      label="Convênio"
      source="agreement"
      reference="agreement"
      isRequired
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput required={true} source="code" label="Código" />
    <NumberInput source="max_usage" label="Máximo de utilizações" fullWidth />
    <NumberInput source="valid_for" label="Valido Por (mêses)" />
    <DateInput source="valid_until" label="Valido até" />
    <BooleanInput source="active" label="Ativo" defaultValue={true} />
  </SimpleForm>
);
