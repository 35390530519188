import React, { useEffect, useState } from "react";
import { TextInput } from "react-admin";
import { useField } from "react-final-form";
import { partiuClient } from "../../axios";

export const CEPInput = React.forwardRef((props, ref) => {
  const [loadedCEP, setLoadedCEP] = useState(null);
  const {
    input: { onChange: onStateChange },
  } = useField("state");
  const {
    input: { onChange: onCityChange },
  } = useField("city");
  const {
    input: { onChange: onNeighborhoodChange },
  } = useField("neighborhood");
  const {
    input: { value: postal_code },
  } = useField("postal_code");
  const {
    input: { onChange: onStreetChange },
  } = useField("street");

  useEffect(() => {
    const _postal_code = postal_code.replace("-", "");
    if (
      !_postal_code ||
      !_postal_code.length ||
      _postal_code.length !== 8 ||
      isNaN(_postal_code) ||
      _postal_code === loadedCEP
    )
      return;

    setLoadedCEP(_postal_code);
  }, [postal_code, setLoadedCEP]);

  useEffect(() => {
    if (!loadedCEP) return;

    partiuClient.api.getAddressFromCEP(loadedCEP).then((data) => {
      if (data.uf) onStateChange(data.uf);
      if (data.localidade) onCityChange(data.localidade);
      if (data.bairro) onNeighborhoodChange(data.bairro);
      if (data.logradouro) onStreetChange(data.logradouro);
    });
  }, [loadedCEP]);

  return <TextInput ref={ref} {...props} label="CEP" source="postal_code" />;
});
