import { Button } from "@chakra-ui/react";
import * as React from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin";

export const MagazineFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      reference="agreement"
      source="agreement"
      label="Convênio"
      alwaysOn
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const MagazineList = (props) => (
  <List
    filters={<MagazineFilter />}
    title="Revistas"
    sort={{ field: "id", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="file" label="Arquivo" />
      <ReferenceField
        label="Convênio"
        source="agreement_id"
        reference="agreement"
      >
        <TextField source="name" label="Convênio" />
      </ReferenceField>
      <DateField showTime={true} source="created_at" label="Criado em" />
      <FunctionField
        label="MOSTRAR"
        render={(record) => (
          <a href={record.file_url} target="_black">
            <Button>mostrar</Button>
          </a>
        )}
      />
    </Datagrid>
  </List>
);
