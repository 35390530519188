import { Devices, Store } from "@material-ui/icons";
import { useRecordContext } from "react-admin";

export const ModeField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);

  if (record[source] === "online") return <Devices />;
  else return <Store />;
};
