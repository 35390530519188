import {
  AutocompleteInput,
  NumberField,
  Resource,
  TextField,
} from "react-admin";
import { Code } from "@material-ui/icons";
import { Apps } from "../../enums";
import { fromFields } from "../../components/core/fromFields";
import { PlanForm } from "./PlanForm";
import { SubscriptionForm } from "./SubscriptionForm";
import { LevelForm } from "./LevelForm";
import { DefaultPlanForm } from "./DefaultPlanForm";
import { MapIntegrationSubscriptionForm } from "./MapIntegrationSubscriptionForm";

export const subscriptionApp = [
  <Resource
    id="level"
    options={{ label: "Níveis", app: Apps.subscriptions }}
    icon={Code}
    name="level"
    {...fromFields(LevelForm, [
      { source: "id", field: NumberField, label: "ID" },
      { source: "name", field: TextField, label: "Nome" },
      { source: "rank", field: NumberField, label: "Rank" },
    ])}
  />,
  <Resource
    id="default_plan"
    options={{ label: "Planos Padrões", app: Apps.subscriptions }}
    icon={Code}
    name="default_plan"
    {...fromFields(DefaultPlanForm, [
      { source: "id", field: NumberField, label: "ID" },
      { source: "name", field: TextField, label: "Nome" },
    ])}
  />,
  <Resource
    id="subscription_plan"
    options={{ label: "Planos", app: Apps.subscriptions }}
    icon={Code}
    name="subscription_plan"
    {...fromFields(PlanForm, [
      { source: "id", field: NumberField, label: "ID" },
      { source: "name", field: TextField, label: "Nome" },
      {
        source: "access_amount",
        field: NumberField,
        label: "Número de Acessos",
      },
      {
        source: "agreement",
        reference: "agreement",
        input: <AutocompleteInput source="name" optionText="name" />,
        field: <TextField source="name" />,
        filter: true,
        alwaysOn: true,
        label: "Convênio",
      },
    ])}
  />,
  <Resource
    id="subscription"
    options={{ label: "Assinatura", app: Apps.subscriptions }}
    icon={Code}
    name="subscription"
    {...fromFields(SubscriptionForm, [
      {
        source: "user",
        reference: "user",
        input: <AutocompleteInput source="name" optionText="name" />,
        field: <TextField source="name" />,
        filter: true,
        alwaysOn: true,
        label: "Usuário",
      },
      { source: "cpf", field: TextField, label: "CPF" },
      {
        source: "plan",
        reference: "subscription_plan",
        input: <AutocompleteInput source="name" optionText="name" />,
        field: <TextField source="name" />,
        filter: true,
        alwaysOn: true,
        label: "Plano",
      },
    ])}
  />,
  <Resource
    id="subscription_mapintegrationplan"
    options={{ label: "Map de Planos (Integração)", app: Apps.subscriptions }}
    icon={Code}
    name="subscription_mapintegrationplan"
    {...fromFields(MapIntegrationSubscriptionForm, [
      { source: "plan_slug_integration", field: TextField, label: "Nome do plano no parceiro" },
      { source: "price_integration", field: NumberField, label: "Preço" },
      {
        source: "agreement",
        reference: "agreement",
        input: <AutocompleteInput source="name" optionText="name" />,
        field: <TextField source="name" />,
        filter: true,
        alwaysOn: true,
        label: "Convenio",
      },
      {
        source: "plan",
        reference: "subscription_plan",
        input: <AutocompleteInput source="name" optionText="name" />,
        field: <TextField source="name" />,
        filter: true,
        alwaysOn: true,
        label: "Plano",
      },
    ])}
  />,
];
