import * as React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  FileField,
  Filter,
  List,
  NumberField,
  TextField
} from "react-admin";

export const MagazineTemplateFilter = (props) => (
  <Filter {...props}> 
    <NumberField source="reference_month" label="Mês de referencia" />
    <NumberField source="reference_year" label="Ano de referencia" />
  </Filter>
);

export const MagazineTemplateList = (props) => (
  <List
    filters={<MagazineTemplateFilter />}
    title="Revistas de Template"
    sort={{ field: "reference_year", order: "DESC" }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" label="ID" />
      <FileField source="file" label="Arquivo" />
      <NumberField source="reference_month" label="Mês de referencia" />
      <NumberField source="reference_year" label="Ano de referencia" />
      <DateField showTime={true} source="created_at" label="Criado em" />
      <EditButton />
    </Datagrid>
  </List>
);
