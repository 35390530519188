import React from "react";
import {
  AutocompleteInput,
  FileField,
  FileInput,
  NumberInput,
  ReferenceInput,
  SimpleForm,
} from "react-admin";

export const AudiobookForm = (props) => {
  return (
    <SimpleForm {...props}>
      <NumberInput required={true} source="position" label="Posição" />
      <ReferenceInput
          reference="book"
          source="book"
          label="Livro"
          alwaysOn
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <FileInput
        required={true}
        source="file"
        label="Arquivo do Livro/Revista"
      >
        <FileField source="file" title="file" />
      </FileInput>
      <FileField source="file" title="file" target="_blank" />
    </SimpleForm>
  );
};
