export const MONTH = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const GRAPH_COLORS = [
  "rgb(229, 58, 53)",
  "#fb8c00",
  "#ffa600",
  "#ff7c43",
  "#f95d6a",
  "#d45087",
  "#a05195",
  "#665191",
  "#2f4b7c",
];
