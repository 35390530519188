import React from "react";
import { Pie } from "react-chartjs-2";
import { GRAPH_COLORS } from "../../../constants";
import { GraphBox } from "../../molecules/GraphBox";

export function CategoryGraph({ data }) {
  let cleanData = data.filter((d) => d[1] > 0).sort((a, b) => a[1] < b[1]);
  cleanData = [
    ...cleanData.slice(0, cleanData.length - 6),
    [
      "Outros",
      cleanData
        .slice(cleanData.length - 6, cleanData.length)
        .map((d) => d[1])
        .reduce((p = 0, c) => p + c),
    ],
  ];

  return (
    <GraphBox flex="1" maxWidth="400px" title="Uso por Categoria">
      <Pie
        height="100%"
        width="100%"
        data={{
          labels: cleanData.map((d) => d[0]),
          datasets: [
            {
              label: "Utilização",
              data: cleanData.map((d) => d[1]),
              backgroundColor: GRAPH_COLORS,
            },
          ],
        }}
      />
    </GraphBox>
  );
}
