import { Button, Card, Stack, Text, VStack, useToast } from '@chakra-ui/react';
import { useNotificationCentralContext } from '../notification-central.context';
import { format } from 'date-fns';
import { useCreateAgreementNotifications } from './hooks';

export function ConfirmEmailStep({ HeaderComponent, colors, history }) {
  const toast = useToast();

  const { formData } = useNotificationCentralContext();

  const translateDayToPortuguese = (day) => {
    switch (day) {
      case 'monday':
        return 'Segunda';
      case 'tuesday':
        return 'Terça';
      case 'wednesday':
        return 'Quarta';
      case 'thursday':
        return 'Quinta';
      case 'friday':
        return 'Sexta';
      case 'saturday':
        return 'Sábado';
      case 'sunday':
        return 'Domingo';
    }
  };

  const mutation = useCreateAgreementNotifications({
    onSuccess() {
      toast({
        title: 'Sucesso!',
        description:
          'Email enviado com sucesso. É possível que o email demore alguns minutos dependendo da quantidade de recipientes.',
        status: 'success',
      });
      history.push('/notifications_emails');
    },
    onError() {
      toast({
        title: 'Erro',
        description: 'Não foi possível enviar o email. Por favor entre em contato em: suporte@partiu.com.br',
        status: 'error',
      });
    },
  });

  return (
    <>
      <HeaderComponent title={'Confirmação dos dados'} />
      <Stack direction={['column', 'row']} width={'100%'} alignItems={'flex-start'} spacing={5}>
        <Card width="50%" title="Enviar Email" padding={4}>
          <VStack height={'100%'} spacing={5} alignItems={'flex-start'}>
            <Text fontWeight={'bold'}>Assunto do Email</Text>
            <Text fontSize={'sm'}>{formData?.emailSubject}</Text>
            {formData?.type === 'one_time' ? (
              <>
                <Text fontWeight={'bold'}>Data de Envio</Text>
                <Text fontSize={'sm'}>{formData?.date && format(formData?.date, 'dd/MM/yyyy HH:mm')}</Text>
              </>
            ) : (
              <>
                <Text fontWeight={'bold'}>Dias da Semana</Text>
                <Text fontSize={'sm'}>{formData?.daysOfWeek.map(translateDayToPortuguese).join(', ')}</Text>
                <Text fontWeight={'bold'}>Hora de Envio</Text>
                <Text fontSize={'sm'}>{formData?.time}</Text>
              </>
            )}
          </VStack>
          <Button
            marginTop={4}
            width={'100%'}
            colorScheme={'green'}
            isLoading={mutation.isLoading}
            onClick={() => mutation.mutate(formData)}
          >
            Confirmar e Enviar
          </Button>
        </Card>
        <Card width="50%">
          <div dangerouslySetInnerHTML={{ __html: formData?.html }}></div>
        </Card>
      </Stack>
    </>
  );
}
