export const templateBuilder = (logo, colors, emailTitle, emailBody) => {
  return {
    counters: {
      u_column: 5,
      u_row: 4,
      u_content_button: 2,
      u_content_text: 4,
      u_content_image: 4,
      u_content_html: 1,
      u_content_heading: 1,
      u_content_divider: 1,
    },
    body: {
      id: 'N9rxcNEQ74',
      rows: [
        {
          id: 'ob-AMpJ4uN',
          cells: [1],
          columns: [
            {
              id: 'O4Zdacs_05',
              contents: [
                {
                  id: 'X-Y0irqx9m',
                  type: 'heading',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    headingType: 'h4',
                    fontSize: '16px',
                    textAlign: 'center',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#0000ee',
                      linkHoverColor: '#0000ee',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_heading_1', htmlClassNames: 'u_content_heading' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<span>Seu Clube de Vantagens tem uma novidade para você:</span>',
                  },
                },
                {
                  id: 'IXd-bbtu5E',
                  type: 'image',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    src: {
                      url: `https://admin.partiu.com.br${logo}`,
                      width: 2080,
                      height: 1000,
                      autoWidth: false,
                      maxWidth: '29%',
                    },
                    textAlign: 'center',
                    altText: '',
                    action: { name: 'web', values: { href: '', target: '_blank' } },
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_image_4', htmlClassNames: 'u_content_image' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    pending: false,
                  },
                },
                {
                  id: 'CD39Lxhs1X',
                  type: 'divider',
                  values: {
                    width: '100%',
                    border: { borderTopWidth: '5px', borderTopStyle: 'solid', borderTopColor: colors.primaryColor, opacity: 0.6 },
                    textAlign: 'center',
                    containerPadding: '10px',
                    anchor: '',
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_divider_1', htmlClassNames: 'u_content_divider' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
                {
                  id: 'QtPVzD6DLI',
                  type: 'text',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    fontSize: '18px',
                    textAlign: 'center',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#0000ee',
                      linkHoverColor: '#0000ee',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_text_3', htmlClassNames: 'u_content_text' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: `<p style="line-height: 140%;"><strong>${emailTitle}</strong></p>`,
                  },
                },
                {
                  id: '2T1gUq1WKf',
                  type: 'text',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    fontSize: '14px',
                    textAlign: 'left',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#0000ee',
                      linkHoverColor: '#0000ee',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_text_4', htmlClassNames: 'u_content_text' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: `<p style="line-height: 140%;">${emailBody}</p>`,
                  },
                },
              ],
              values: {
                backgroundColor: '',
                padding: '0px',
                border: {},
                borderRadius: '0px',
                _meta: { htmlID: 'u_column_5', htmlClassNames: 'u_column' },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: '',
            columnsBackgroundColor: '',
            backgroundImage: { url: '', fullWidth: true, repeat: 'no-repeat', size: 'custom', position: 'center' },
            padding: '0px',
            anchor: '',
            _meta: { htmlID: 'u_row_4', htmlClassNames: 'u_row' },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
        {
          id: 'yuHZom3zGN',
          cells: [1],
          columns: [
            {
              id: 'KL21lh0Rzv',
              contents: [
                {
                  id: 'hIN6XXu2OE',
                  type: 'button',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    href: { name: 'web', values: { href: '', target: '_blank' } },
                    buttonColors: {
                      color: '#010101',
                      backgroundColor: colors.primaryColor,
                      hoverColor: '#FFFFFF',
                      hoverBackgroundColor: '#3AAEE0',
                    },
                    size: { autoWidth: true, width: '100%' },
                    fontWeight: 700,
                    fontSize: '14px',
                    textAlign: 'center',
                    lineHeight: '120%',
                    padding: '10px 20px',
                    border: {},
                    borderRadius: '4px',
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_button_2', htmlClassNames: 'u_content_button' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<span style="line-height: 16.8px;">Acessar o clube</span>',
                    calculatedWidth: 147,
                    calculatedHeight: 37,
                  },
                },
              ],
              values: {
                backgroundColor: '',
                padding: '0px',
                border: {},
                borderRadius: '0px',
                _meta: { htmlID: 'u_column_1', htmlClassNames: 'u_column' },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: '',
            columnsBackgroundColor: '',
            backgroundImage: {
              url: '',
              fullWidth: true,
              repeat: 'no-repeat',
              size: 'custom',
              position: 'center',
              customPosition: ['50%', '50%'],
            },
            padding: '0px',
            anchor: '',
            hideDesktop: false,
            _meta: { htmlID: 'u_row_1', htmlClassNames: 'u_row' },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
        {
          id: 'jSLpbyV5ye',
          cells: [1, 2],
          columns: [
            {
              id: 'caZRvm5pB1',
              contents: [
                {
                  id: 'SzFjOt6Dja',
                  type: 'text',
                  values: {
                    containerPadding: '21px',
                    anchor: '',
                    fontSize: '20px',
                    textAlign: 'center',
                    lineHeight: '150%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#0000ee',
                      linkHoverColor: '#0000ee',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: { htmlID: 'u_content_text_1', htmlClassNames: 'u_content_text' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 150%;"><strong>Baixe nosso App</strong></p>',
                  },
                },
              ],
              values: {
                backgroundColor: colors.primaryColor,
                padding: '0px',
                border: {},
                borderRadius: '0px',
                _meta: { htmlID: 'u_column_2', htmlClassNames: 'u_column' },
              },
            },
            {
              id: 'PA-t7NJYJ4',
              contents: [
                {
                  id: '8qPe1WwVfn',
                  type: 'html',
                  values: {
                    html: '<div style="display: flex; flex-direction: row; gap: 5px; flex-wrap: wrap; width: 100%; height: 80px;\nalign-items:center; justify-content: center;   \n">\n<img\n  src=\'https://partiu-static.s3.amazonaws.com/badge/googleplay.png\'\n  style="object-fit: contain; max-width: 40%;"\n/>\n<img\n  src=\'https://partiu-static.s3.amazonaws.com/badge/appstore.png\'\n  style="object-fit: contain; max-width: 40%;"\n/>\n</div>',
                    hideDesktop: false,
                    displayCondition: null,
                    containerPadding: '10px',
                    anchor: '',
                    _meta: { htmlID: 'u_content_html_1', htmlClassNames: 'u_content_html' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
              ],
              values: {
                backgroundColor: colors.primaryColor,
                padding: '0px',
                border: {},
                borderRadius: '0px',
                _meta: { htmlID: 'u_column_3', htmlClassNames: 'u_column' },
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: '',
            columnsBackgroundColor: '',
            backgroundImage: { url: '', fullWidth: true, repeat: 'no-repeat', size: 'custom', position: 'center' },
            padding: '0px',
            anchor: '',
            hideDesktop: false,
            _meta: { htmlID: 'u_row_2', htmlClassNames: 'u_row' },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      headers: [],
      footers: [],
      values: {
        popupPosition: 'center',
        popupWidth: '600px',
        popupHeight: 'auto',
        borderRadius: '10px',
        contentAlign: 'center',
        contentVerticalAlign: 'center',
        contentWidth: '500px',
        fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
        textColor: colors.primaryFontColor,
        popupBackgroundColor: '#FFFFFF',
        popupBackgroundImage: { url: '', fullWidth: true, repeat: 'no-repeat', size: 'cover', position: 'center' },
        popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
        popupCloseButton_position: 'top-right',
        popupCloseButton_backgroundColor: '#DDDDDD',
        popupCloseButton_iconColor: '#000000',
        popupCloseButton_borderRadius: '0px',
        popupCloseButton_margin: '0px',
        popupCloseButton_action: {
          name: 'close_popup',
          attrs: { onClick: "document.querySelector('.u-popup-container').style.display = 'none';" },
        },
        backgroundColor: '#ffffff',
        preheaderText: '',
        linkStyle: {
          body: true,
          linkColor: '#0000ee',
          linkHoverColor: '#0000ee',
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        backgroundImage: { url: '', fullWidth: true, repeat: 'no-repeat', size: 'custom', position: 'center' },
        _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
      },
    },
    schemaVersion: 16,
  };
};
