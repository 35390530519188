import React from "react";
import { useRecordContext } from "react-admin";

export function MoneyField(props) {
  const { source } = props;
  const record = useRecordContext(props);
  let value = record[source];

  if (value == null) value = 0;

  return (
    <p style={{ textAlign: "right", margin: 0 }}>
      {new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "brl",
      }).format(value)}
    </p>
  );
}
