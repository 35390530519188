import { AutocompleteInput, Resource, TextField } from "react-admin";
import { Code } from "@material-ui/icons"
import { Apps } from "../../enums";
import { PromocodeBatchForm } from "./promocode_batch/PromocodeBatchForm";
import { PromocodeForm } from "./promocode/PromocodeForm";
import { fromFields } from "../../components/core/fromFields";

export const promocodeApp = [
    <Resource
        id="promocode_batch"
        options={{ label: "Lotes de Código Promocional", app: Apps.promocodes }}
        icon={Code}
        name="promocode_batch"
        {...fromFields(PromocodeBatchForm, [
            { source: "name", field: TextField, label: "Nome" },
            {
                source: "agreement",
                reference: "agreement",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Convênio",
            },
        ])}
    />,
    <Resource
        id='promocode'
        options={{ label: "Código Promocional", app: Apps.promocodes }}
        icon={Code}
        name="promocode"
        {...fromFields(PromocodeForm, [
            {
                source: "batch",
                reference: "promocode_batch",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Lote",
            },
            { source: "code", field: TextField, label: "Código" },
            {
                source: "agreement",
                reference: "agreement",
                input: <AutocompleteInput source="name" optionText="name" />,
                field: <TextField source="name" />,
                filter: true,
                alwaysOn: true,
                label: "Convênio",
            },
        ])}
    />
]