import {
  Input,
  VStack,
  Button,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Labeled } from "../../../components/molecules/Labeled";
import { slugfy } from "../../../utils";
import React from "react";
import { createNewApp, editApp, runBuild, updateBuild } from "./api";
import { AutocompleteInput, Filter, ReferenceInput } from "react-admin";
import { InputImage } from "./InputImage";

const MagazineFilter = (props) => {
  return (
    <Filter {...props}>
      <ReferenceInput
        reference="agreement"
        source="agreement"
        label="Convênio"
        alwaysOn
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const Step1 = ({ state, changeStep, setState }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const id = state?.id;

  const { getValues, register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      version: '1.0.0',
      versionCode: '1',
    }
  });

  const name = watch("name");
  const slug = watch("slug");
  const toast = useToast();

  React.useEffect(() => {
    if (id && state) {
      setValue("name", state.name);
      setValue("slug", state.slug);
      setValue("version", state.version || "1.0.0");
      setValue("versionCode", state.versionCode || "1");
      setValue("bundleIdentifier", state.bundleIdentifier);
      setValue("appBaseUrl", state.appBaseUrl);
      setValue("icon", state.icon);
      setValue("splash", state.splash);
      setValue("iosPurchaseToken", state.iosPurchaseToken);
      setValue("androidPurchaseToken", state.androidPurchaseToken);
      setValue("agreement", state.agreement);
    }
  }, [id, state]);

  React.useEffect(() => {
    if (!id) {
      setValue("slug", slugfy(slug));
      setValue("bundleIdentifier", `br.com.partiu.${slugfy(slug)}`);
    }
  }, [slug, id]);

  const handleSubmitApp = async (formData) => {
    try {
      setIsLoading(true);

      if (!formData.agreement) {
        throw new Error("Convênio é obrigatório");
      }

      if (!formData.icon) {
        throw new Error("Icone do App é obrigatório");
      }

      if (!formData.splash) {
        throw new Error("Splash Screen do App é obrigatório");
      }

      formData.bundleIdentifier = `br.com.partiu.${slugfy(formData.slug)}`;

      if (!formData.appBaseUrl.includes("http")) {
        throw new Error("Url do App inválida");
      }

      try {
        formData.androidPurchaseToken =
          formData.androidPurchaseToken || "vazio";

        if (id) {
          if (formData.icon === state.icon) {
            delete formData.icon;
          }
          if (formData.splash === state.splash) {
            delete formData.splash;
          }

          const { data: response } = await editApp(id, formData);
          setState(response);
        } else {
          const { data: response } = await createNewApp(formData);

          setState(response);
        }
      } catch (error) {
        throw new Error("Erro ao salvar o app");
      }

      toast({
        title: "App salvo com sucesso!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      return;
    } catch (error) {
      toast({
        title: error.message,
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const buildUpdate = async () => {
    try {
      setIsLoading(true);

      const data = getValues();

      await handleSubmitApp(data);

      await updateBuild(id);

      // onOpen();
    } catch (error) {
      toast({
        title: "Erro ao dar update na build",
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const buildApp = async () => {
    try {
      setIsLoading(true);

      const data = getValues();

      await handleSubmitApp(data);

      await runBuild(id);

      onOpen();
    } catch (error) {
      toast({
        title: "Erro ao iniciar a build",
        description: "Entre em contato com a equipe de desenvolvimento.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form style={{ width: "100%" }} onSubmit={handleSubmit(handleSubmitApp)}>
        <VStack spacing={4}>
          <Labeled label={"Selecione um convênio"}>
            <MagazineFilter
              filterValues={{ agreement: state?.agreement }}
              setFilters={(value) => setValue("agreement", value.agreement)}
            />
          </Labeled>
          <Labeled label={"Nome do App"}>
            <Input
              {...register("name", {
                required: true,
              })}
            />
          </Labeled>
          <Labeled label={"Versão"}>
            <Input {...register("version", { required: true })} />
          </Labeled>
          <Labeled label={"Código de Versão"}>
            <Input {...register("versionCode", { required: true })} />
          </Labeled>
          <Labeled label={"Slug (Nome Sem espaços)"}>
            <Input
              {...register("slug", {
                required: true,
              })}
              disabled={id}
            />
          </Labeled>
          <Labeled label={"Identificador Unico (br.com.partiu.*)"}>
            <Input
              {...register("bundleIdentifier", {
                required: true,
              })}
              disabled
            />
          </Labeled>
          <Labeled label={"Url do App"}>
            <Input {...register("appBaseUrl", { required: true })} />
          </Labeled>
          <Labeled label={"Icone do App (1024x1024)"}>
            <InputImage
              value={state?.icon}
              onUpload={(url) => setValue("icon", url)}
              imageName={`${slugfy(name)}-icon`}
            />
          </Labeled>
          <Labeled label={"Splash Screen do App (1080x1920)"}>
            <InputImage
              value={state?.splash}
              onUpload={(url) => setValue("splash", url)}
              imageName={`${slugfy(name)}-splash`}
            />
          </Labeled>
          <Labeled label={"Ios Revenue Cat Token"}>
            <Input {...register("iosPurchaseToken", { required: true })} />
          </Labeled>
          <Labeled label={"Android Revenue Cat Token"}>
            <Input {...register("androidPurchaseToken", { required: false })} />
          </Labeled>
          <Button isLoading={isLoading} type="submit">
            Salvar
          </Button>
          {id && (
            <Button
              isLoading={isLoading}
              onClick={async () => {
                state.status === "created" ? buildApp() : buildUpdate();
              }}
            >
              {state.status === "created" ? "Gerar Build" : "Update Build"}
            </Button>
          )}
        </VStack>
      </form>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>App Criado com Sucesso!</ModalHeader>
          <ModalBody>
            O aplicativo foi criado com sucesso. Aguarde a conclusão das builds
            para Android e iOS, o que pode levar de 5 a 10 minutos. Você pode
            acompanhar o progresso em{" "}
            <a
              href="https://expo.dev/accounts/partiu.vantagens"
              target="_blank"
              rel="noreferrer"
              s
              style={{ color: "blue" }}
            >
              Expo Dev
            </a>
            . Após a conclusão, você poderá prosseguir para a segunda etapa, que
            é submeter as builds geradas nas lojas.
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onClose();
                changeStep();
              }}
              variant="ghost"
            >
              Continuar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
