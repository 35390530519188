import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ChakraProvider } from "@chakra-ui/react";

import "./global.css";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { App } from "./App";

Chart.plugins.register(ChartDataLabels);

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  color: "black",
  align: "end",
  backgroundColor: "rgba(200,200,200,0.7)",
  borderRadius: 100,
  font: {
    size: 10,
  },
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
