import { Button } from "@material-ui/core";
import React from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext
} from "react-admin";
import { ModeField } from "../../../components/atoms/ModeField";
import { MoneyField } from "../../../components/atoms/MoneyField";
import { QualityTagField } from "../../../components/atoms/QualityTagField";
import { ToggleInputField } from "../../../components/atoms/ToggleInputField";

const ApproveDenyField = (props) => {
  const { resource } = props;
  const record = useRecordContext(props);
  const dataProvider = useDataProvider();

  const handleChange = (event) => {
    dataProvider.update(resource, {
      id: record.id,
      data: { pending_approval: false, active: true },
    });
  };

  if (record.pending_approval)
    return (
      <div style={{ display: "flex" }}>
        <Button onClick={handleChange} color="primary" variant="contained">
          Aprovar
        </Button>
      </div>
    );
  else return <></>;
};

export const DiscountFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="Busca por nome" alwaysOn />
    <ReferenceInput
      reference="company"
      source="company"
      label="Empresa"
      alwaysOn
    >
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <ReferenceInput reference="store" source="stores" label="Loja" alwaysOn>
      <AutocompleteInput source="name" />
    </ReferenceInput>
    <SelectInput
      source="mode"
      choices={[
        { id: "online", name: "E-Commerce" },
        { id: "local", name: "Físico" },
      ]}
      label="Modo de Desconto"
      alwaysOn
    />
    <SelectInput
      source="type"
      choices={[
        { id: "discount", name: "Desconto" },
        { id: "buy_and_gain", name: "Compre e Ganhe" },
        { id: "treat", name: "Mimo" },
      ]}
      label="Tipo de Desconto"
    />
    <BooleanInput source="featured" label="Destaque" />
    <BooleanInput source="active" label="Ativo" />
  </Filter>
);

const DiscountPagination = (props) => (
  <Pagination rowsPerPageOptions={[5, 10, 15]} {...props} />
);

export const DiscountList = (props) => {
  return (
    <List
      sort={{ field: "id", order: "DESC" }}
      perPage={5}
      filters={<DiscountFilter />}
      pagination={<DiscountPagination />}
      title="Descontos"
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />
        <ModeField source="mode" />
        <ReferenceField reference="company" source="company" label="Empresa">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" label="Nome" />
        <TextField source="type" label="Tipo" />
        <NumberField source="discount" label="Desconto (%)" />
        <MoneyField source="original_price" label="Ticket Médio" />
        <MoneyField source="gain_per_original_price" label="Ganho por Ticket Médio" />
        <NumberField source="usage" label="Utilização" />
        <MoneyField source="total_transacted" label="Total Transacionado" />
        <ToggleInputField source="featured" label="Destaque" />
        <QualityTagField
          source="registration_quality"
          label="Qualidade Cadastro"
        />
        <ToggleInputField source="active" label="Ativo" />
        <DateField source="created_at" label="Criado em" />
        <ApproveDenyField />
        <EditButton />
      </Datagrid>
    </List>
  );
};
