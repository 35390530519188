import axios from "axios";
import { axiosInstance, baseUrl, isLogged, setAccessToken } from "../axios";

const authProvider = {
  login: async ({ username, password }) => {
    return axios
      .post(`${baseUrl}/api/v1/users/tokens/`, {
        username,
        password,
      })
      .then(({ data }) => {
        setAccessToken(data.token);
        localStorage.setItem("token", data.token);
        return data.token;
      });
  },
  checkAuth: () => {
    return isLogged() ? Promise.resolve() : Promise.reject();
  },
  logout: () => {
    setAccessToken(null);
    return Promise.resolve();
  },
  checkError: ({ status }) => {
    if (status === 401) {
        return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: async () => {
    Promise.resolve();
  },
  getIdentity: async () => {
    const { data } = await axiosInstance.get("/api/v1/profile/");
    return {
      id: data.id,
      fullName: data.full_name,
      avatar: data.image,
    };
  },
};

export default authProvider;
