import React, { useEffect, useState } from "react";
import {
  BooleanInput,
  FormTab,
  TabbedForm,
  TextInput,
  DateTimeInput,
  DateInput,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

import { useFormState } from "react-final-form";
import { Hint } from "../../../components/atoms/Hint";

import { FormBox } from "../../../components/atoms/FormBox";
import { FormTitle } from "../../../components/atoms/FormTitle";
import { FormText } from "../../../components/atoms/FormText";
import { DiscountTable } from "../../../components/atoms/DiscountTable";

const PreviewInput = (props) => {
  const [preview, setPreview] = useState("");
  const { values } = useFormState();
  const { source } = props;
  const id = source.split("[")[1].split("]")[0];
  const type = source.split("[")[0];

  useEffect(() => {
    setPreview(
      (values[type][id] || "")
        .replace("{discount_name}", "Desconto em roupas")
        .replace("{company_name}", "Marisa")
        .replace("{discount_type}", "15% de desconto.")
    );
  }, [values, props.index]);

  return (
    <div>
      <TextInput {...props} fullWidth />
      <p>Exemplo: {preview}</p>
    </div>
  );
};

export const CommunicationBatchTabbedForm = (props) => {
  return (
    <TabbedForm {...props}>
      <FormTab label="Passo 1 - Informações">
        <FormBox>
          <FormTitle>Lotes de Comunicação</FormTitle>
          <FormText>
            Este formulário é voltado para criação de vários push notifications
            de uma vez só.
            <br /> OBS:{" "}
            <b>
              após apertar em salvar, espere pois o processo pode demorar
              diversos minutos.
            </b>
          </FormText>
        </FormBox>
        <TextInput isRequired source="name" label="Nome (para uso interno)" />
        <DateInput isRequired source="starts_at" label="Começa em" />
        <Hint>
          Este <b>dia está incluido</b>, ou seja, será{" "}
          <b>enviado push neste dia</b>.
        </Hint>
        <DateInput isRequired source="ends_at" label="Termina em" />
        <Hint>
          Este <b>dia não está incluido</b>, ou seja,{" "}
          <b>não será enviado push no dia de término</b>.
        </Hint>
        <DateTimeInput
          defaultValue={new Date()}
          isRequired
          source="time"
          label="Lançar às"
        />
        <Hint>Apenas a parte de horário será utilizada</Hint>

        <BooleanInput
          source="send_push_notification"
          label="Enviar por Push Notification"
        />
        <BooleanInput
          source="send_internal_notification"
          label="Enviar por Notificação Interna"
        />
      </FormTab>
      <FormTab label="Passo 2 - Descontos">
        <FormBox>
          <FormTitle>Descontos</FormTitle>
          <FormText>
            Selecione os descontos que poderão ser mostrados nos push
            notifications.
          </FormText>
        </FormBox>
        <DiscountTable isRequired source="discounts" label="Descontos" />
      </FormTab>
      <FormTab label="Passo 3 - Textos">
        <FormBox>
          <FormTitle>Como Usar</FormTitle>
          <FormText>
            Digite os textos que irão aparecer como título e texto das
            mensagens.
            <br /> Para adicionar informações como: nome do desconto, tipo do
            desconto ou nome da empresa utilize os códigos abaixo:
          </FormText>
          <ul style={{ fontSize: 15, marginLeft: 20 }}>
            <li>
              {"{discount_name}"} - Nome do desconto ex: Desconto no Crepe, Mimo
              de perfume...
            </li>
            <li>
              {"{discount_type}"} - Tipo do desconto ex: 15% de desconto, Compre
              e Ganhe ou Mimo
            </li>
            <li>
              {"{company_name}"} - Nome da empresa ex: Marisa, Americanas,
              Netshoes...
            </li>
          </ul>
        </FormBox>
        <ArrayInput isRequired source="titles" label="Títulos">
          <SimpleFormIterator>
            <PreviewInput label="" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput isRequired source="texts" label="Textos">
          <SimpleFormIterator>
            <PreviewInput label="" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  );
};
