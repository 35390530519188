import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { axiosInstanceV2 } from '../../../axios';

function BookDashTable({bookId}) {

  const toast = useToast();

  function getDashboardDataByBook() {
      return axiosInstanceV2.get(`/bookstore/book_dashboard_admin/${bookId}/`).then((response) => response.data);
  }

  const booksQuery = useQuery(["getDashboardDataByBook", bookId], getDashboardDataByBook, {enabled: !!bookId, retry: 0});
  
  const mutateIndexBook = useMutation((data) => axiosInstanceV2.post(`/bookstore/index_book_admin/`, data), {
    onSuccess: () => {
      toast({
        title: "Livro indexado com sucesso",
        status: "success",
        duration: 3000,
      })
    },
    onError: () => {
      toast({
        title: "Erro ao indexar livro",
        status: "error",
        duration: 3000,
      })
    },
  })

  if (booksQuery.isLoading) {
    return <div>Loading...</div>
  }
  return (
    <>
    <Box p={2} flex={1} paddingX={6}>
      <Box>
        Quantidade de usuários vinculados: {booksQuery.data.quantity_book_user_by_type}
      </Box>
      <Box>
        Quantidade de usuários elegíveis: {booksQuery.data.users_able_to_get_this_book}
      </Box>
      <Box my={2}>
        <Button onClick={() => mutateIndexBook.mutateAsync({
                      book_id: bookId,
                      agreement_id: null
                    })} disabled={mutateIndexBook.isLoading}>{mutateIndexBook.isLoading ? 'Carregando' : 'Reindexação Full'}</Button>
      </Box>
      <Box my={2}>
        <Button onClick={booksQuery.refetch} disabled={booksQuery.isLoading || booksQuery.isRefetching}>{booksQuery.isLoading || booksQuery.isRefetching ? 'Carregando' : 'Atualizar'}</Button>
      </Box>
    </Box>
    <Box p={2} overflow={"auto"}>
      <TableContainer backgroundColor={"#ccc"} height={"100%"} maxHeight={"500px"} overflowY={"auto"}>
        <Table variant='striped' size="sm">
          <Thead>
            <Tr>
              <Th>Convênio</Th>
              <Th>Usuários elegíveis</Th>
              <Th isNumeric>Total de Vínculos</Th>
              <Th isNumeric>Opções</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              booksQuery.data.users_able_to_get_this_book_by_agreement.map((item) => (
                <Tr key={item.agreement.id + '_bookdash_row'}>
                  <Td>{item.agreement.name}</Td>
                  <Td>{item.users_able_to_get_this_book}</Td>
                  <Td isNumeric>{item.quantity_book_user_by_type}</Td>
                  <Td isNumeric>
                  {item.users_able_to_get_this_book !== item.quantity_book_user_by_type && (
                    <Button size="sm" colorScheme="blue" 
                      onClick={() => mutateIndexBook.mutateAsync({
                        book_id: bookId,
                        agreement_id: item.agreement.id
                      })} 
                      disabled={mutateIndexBook.isLoading}
                    >
                      {mutateIndexBook.isLoading ? 'Carregando' : 'Reindexar'}
                    </Button>
                  )}
                  </Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
    </> 
  )
}

export default BookDashTable;