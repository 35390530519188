import {
  Box,
  Button,
  Divider,
  Select,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { useQuery } from 'react-query';
import { axiosInstanceV2 } from '../../axios';
import Book from './components/Book';
import BookDashTable from './components/BookDashTable';
import Card from './components/Card';

// import { Container } from './styles';

function BooksDashboard() {

  const [month, setMonth] = React.useState(new Date().getMonth() + 1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [type, setType] = React.useState('book');

  function getFeaturedBooks(m,y,t) {
      return axiosInstanceV2.get(`/bookstore/book_dashboard_admin/?month=${m}&year=${y}&type=${t}`).then((response) => response.data);
  }
  const booksQuery = useQuery(["book_dashboard_admin", month, year, type],() => getFeaturedBooks(month, year, type), {
    retry: 0,
    enabled: false,
  });

  return (
    <Box backgroundColor="">
      <Box display={"flex"} marginY={4} >
        <Text fontSize='2xl' fontWeight={"bold"}>Dashboard de Livros</Text>
        <Box flex={1} display={"flex"} justifyContent={"flex-end"}>
          <Select placeholder="Selecione o mês" width={"250px"} marginX={4} value={month} onChange={(e) => setMonth(e.target.value)}>
            <option value="1">Janeiro</option>
            <option value="2">Fevereiro</option>
            <option value="3">Março</option>
            <option value="4">Abril</option>
            <option value="5">Maio</option>
            <option value="6">Junho</option>
            <option value="7">Julho</option>
            <option value="8">Agosto</option>
            <option value="9">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </Select>
          <Select placeholder="Selecione o ano" width={"250px"} marginX={4} value={year} onChange={(e) => setYear(e.target.value)}>
            <option value="2025">2025</option>
            <option value="2024">2024</option>
            <option value="2023">2023</option>
          </Select>
          <Select placeholder="Selecione o tipo de mídia" width={"250px"} marginX={4} value={type} onChange={(e) => setType(e.target.value)}>
            <option value="book">Livro</option>
            <option value="magazine">Revista</option>
            <option value="audiobook">Audiobook</option>
          </Select>
          <Button onClick={() => booksQuery.refetch()}>
            Filtrar
          </Button>
        </Box>
      </Box>
      {booksQuery.isLoading && <div>Loading...</div>}
      {booksQuery.isError && <div>Error...</div>}
      {booksQuery.isSuccess && (
        <>
          <SimpleGrid columns={{ sm: 2, md: 6 }} spacing={10}>
            <Card title="Total de Livros" bg={"#E6583E"} number={booksQuery.data.quantity_book_by_type.find(q => q.type == 'book')['total_type']} color={"white"}/>
            <Card title="Total de Convênios de Livros" bg={"#E6583E"} number={booksQuery.data.quantity_agreement_by_type.find(q => q.bookstore_types && q.bookstore_types.includes('book'))['total_type']} color={"white"}/>
            <Card title="Total de Revistas" bg={"#f8b63d"} number={booksQuery.data.quantity_book_by_type.find(q => q.type == 'magazine')['total_type']} color={"white"}/>
            <Card title="Total de Convênios de Revistas" bg={"#f8b63d"} number={booksQuery.data.quantity_agreement_by_type.find(q => q.bookstore_types && q.bookstore_types.includes('magazine'))['total_type']} color={"white"}/>
            <Card title="Total de Audiobooks" bg={"#a4a4a4"} number={booksQuery.data.quantity_book_by_type.find(q => q.type.includes('audiobook'))['total_type']} color={"white"}/>
            <Card title="Total de Convênios de Audiobooks" bg={"#a4a4a4"} number={booksQuery.data.quantity_agreement_by_type.find(q => q.bookstore_types && q.bookstore_types.includes('audiobook'))['total_type']} color={"white"}/>
          </SimpleGrid>
          <Divider height={"2px"} backgroundColor={"#ccc"} marginY={8}/>
          <Box display={"flex"} marginY={4} >
            <Text fontSize='2xl' fontWeight={"bold"}>Livros do mês</Text>
          </Box>
          <VStack align={"flex-start"} spacing={6}>
            {booksQuery.data.books.length === 0 && <Text>Nenhum livro encontrado</Text>}
            {
              booksQuery.data.books?.map((book) => (
                <Box display={"flex"} w="100%" borderRadius={"lg"} border="1px solid #ccc" p={5}>
                  <Book cover={book.cover} title={book.name} id={book.id} createdAt={book.created_at.replace('T', ' ')}/>
                  <BookDashTable bookId={book.id}/>
                </Box>
            ))
          }
          </VStack>
        </>
      )}
    </Box>
  )
}

export default BooksDashboard;