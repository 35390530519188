import {
  Center,
  CircularProgress,
  Flex,
  Stack,
  VStack
} from "@chakra-ui/react";
import {
  faChartBar,
  faDollarSign,
  faNetworkWired,
  faStore,
  faUser,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../axios";
import { useCheckMobile } from "../../utils";
import { BigStatisticCard } from "../molecules/BigStatisticCard";
import { StatisticCard } from "../molecules/StatisticCard";
import { ActivationUsageGraph } from "./graphs/ActivationUsageGraph";
import { CategoryGraph } from "./graphs/CategoryGraph";
import { HighestUsageGraph } from "./graphs/HighestUsageGraph";
import { UserRankGraph } from "./graphs/UserRankGraph";
import { WeekdayUsageGraph } from "./graphs/WeekdayUsageGraph";
import { UserModal } from "./UserModal";

export const Statistics = () => {
  const mobile = useCheckMobile();
  const [selectedUser, setSelectedUser] = useState();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get("/api/v1/agreement/report").then(({ data }) => {
      setData(data);
      setLoading(false);
    });
  }, [setData]);

  if (loading || Object.keys(data) === 0)
    return (
      <Flex direction="column" alignItems="flex-start">
        <Center width="100%" marginTop="50px">
          <CircularProgress isIndeterminate />
        </Center>
      </Flex>
    );

  return (
    <Flex direction="column" alignItems="flex-start">
      <UserModal
        id={selectedUser}
        isOpen={selectedUser != null}
        onClose={() => setSelectedUser(null)}
      />
      <VStack
        alignItems="flex-start"
        spacing={5}
        width="100%"
        margin="auto"
        padding="60px 30px"
        paddingTop="10px"
        direction="column"
      >
        <Stack
          width="100%"
          spacing={5}
          direction={{ base: "column", lg: "row" }}
        >
          <StatisticCard
            title="Conveniados"
            loading={loading}
            icon={faUsers}
            description={data.users.all}
          />
          <StatisticCard
            title="Utilizações"
            loading={loading}
            icon={faChartBar}
            description={data.usage.all}
          />

          <StatisticCard
            title="Economizado"
            loading={loading}
            icon={faDollarSign}
            description={data.usage.total_estimated_saved}
            formatOptions={{ currency: "BRL", style: "currency" }}
          />
        </Stack>
        <ActivationUsageGraph
          userMonthlyCount={data.users.activated_monthly_count}
          usageMonthlyCount={data.usage.monthly_count}
        />
        <Stack
          width="100%"
          spacing={5}
          direction={{ base: "column", lg: "row" }}
        >
          <BigStatisticCard
            title="Utilizações E-commerce"
            icon={faNetworkWired}
            value={data.usage.online}
          />
          <BigStatisticCard
            title="Utilizações Físicas"
            icon={faStore}
            value={data.usage.local}
          />
          <BigStatisticCard
            title="Utilização por Usuário"
            icon={faUser}
            value={Math.round(data.usage.usage_per_user * 100) / 100}
          />
        </Stack>
        <Stack
          width="100%"
          spacing={5}
          direction={{ base: "column", lg: "row" }}
        >
          <WeekdayUsageGraph
            flex={1}
            weekdayUsage={data.usage.week_day_usage}
          />
          <CategoryGraph data={data.usage.category_usage} flex={1} />
        </Stack>
        <Stack
          width="100%"
          spacing={5}
          direction={{ base: "column", lg: "row" }}
        >
          <HighestUsageGraph
            flex={2}
            title="Ranking Lojas"
            highestUsageData={data.usage.total_highest_usage.slice(0, 5)}
          />
          <UserRankGraph
            onClick={(id) => setSelectedUser(id)}
            data={data.usage.highest_user_usage}
          />
        </Stack>
      </VStack>
    </Flex>
  );
};
